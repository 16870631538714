$font-family-sans-serif: "Roboto", "Segoe UI", "Helvetica Neue", Arial,
  "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji" !default;
$mobile-font-family-sans-serif: "Poppins", "Roboto", "Segoe UI", "Helvetica Neue", Arial,
  "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji" !default;
$red: #ff0000;
$yellow: #ffe306;
$green: #20e90e;
$pink: #faaabb;
$blue: #37d8f0;
$orange: #ff9933;
$black: #000000;
$gray-10: #9fa5af !default;
$gray-20: #474747 !default;
$gray-30: #212121 !default;
$gray-40: #d4d4d4 !default;
$gray-44: #444444 !default;
$gray-999: #999999 !default;
$gray-27: #27272d !default;
$body-bg: #e6e9e8 !default;
$primary: #47388c;
$secondary: #9a93e0;
$mobile-color: #2b0055;
$third: #23527c;
$border-color: #c8c9cb !default;
$body-color: $gray-30 !default;
$disabled-color-100: $gray-999;
$disabled-color-200: $gray-999;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  ) !default;

$container-max-widths: (
  // sm: 540px,
  // md: 720px,
  // lg: 960px,
  xl: 1140px) !default;

// $container-max-widths: (
// //   sm: 540px,
// //   md: 720px,
// //   lg: 960px,
//   xl: 1440px
// ) !default;

$font-weight-lighter: lighter !default;
$font-weight-book: 300 !default;
$font-weight-medium: 500 !default;
$font-weight-normal: 400 !default;

$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-black: 900 !default;
$h1-font-size: 36px !default;
$h2-font-size: 32px !default;
$h3-font-size: 28px !default;
$h4-font-size: 24px !default;
$h5-font-size: 20px !default;
$h6-font-size: 18px !default;
$small-font-size: 11px !default;

// DEFAULT HEADER/FOOTER
$header-bg: #84066a !default;
$footer-bg: $green !default;
$footer-color: #8abfae !default;

// Buttons + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$fontsize-lg: 18px !default;
$fontsize-sm: 14px !default;
$fontsize-base: 16px !default;

$input-btn-font-size: $fontsize-base !default;
$input-btn-padding-y: 0.536rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-focus-width: 0rem !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-padding-y-lg: 0.844rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $fontsize-base !default;
$input-border-color: #979797b5 !default;
$input-btn-border-width: 1px !default;

$btn-padding-y: 0.643rem !default;
$btn-padding-x: 1rem !default;

$btn-padding-y-sm: 0.25rem !default;
$btn-padding-x-sm: 1.5rem !default;
$btn-font-size-sm: $fontsize-sm !default;

$btn-padding-y-lg: 0.75rem !default;
$btn-padding-x-lg: 1.5rem !default;
$btn-font-size-lg: $fontsize-lg !default;

$btn-border-width: 0px !default;
$border-radius: 4px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 4px !default;
$input-placeholder-color: #66666650 !default;
$border-color-form: $primary !default;
$bg-secondary: $primary !default;
$form-feedback-icon-valid: "" !default;
$form-feedback-icon-invalid: "" !default;

// Dropdowns
// Dropdown menu container and contents.
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.4rem !default;
$dropdown-spacer: 0rem !default;
$dropdown-divider-margin-y: 0rem !default;
$dropdown-link-active-color: $body-color !default;
$dropdown-link-active-bg: $gray-40 !default;
$dropdown-item-padding-y: 0.4rem !default;
$dropdown-item-padding-x: 1rem !default;

$breakpoint-1: 1330px;
$breakpoint-2: 1200px;
$breakpoint-3: 880px;
$breakpoint-4: 750px;
$breakpoint-650: 650px;

$outline: #d2d4de !default;
$label-blue: #495057 !default;
$flexbox-loader-color: $primary !default;
$custom-all-transition: all 0.15s ease-in-out;

// Modals
$modal-backdrop-bg: #0a0111 !default;
$modal-backdrop-opacity: 0.65 !default;

// $modal-xl: 1140px !default;
// $modal-lg: 800px !default;
// $modal-md: 500px !default;
$modal-sm: 376px !default;
$max-width-odds: 855px !default;
$lay-bg: #f6b771 !default;
$lay-light-bg: #f6b771 !default;
$lay-ultra-light-bg: #f6b771 !default;
$back-bg: #61acde !default;
$back-light-bg: #61acde !default;
$back-ultra-light-bg: #61acde !default;

$back-odd: #61acde;
$lay-odd: #f6b771;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;