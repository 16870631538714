.leftnav-col {
  flex: 0 0 245px;
  max-width: 245px;

  @media (max-width: 1080px) {
    display: none;
  }
}

.betslip-col {
  flex: 0 0 500px;
  max-width: 500px;
}

.odds-banner {
  height: 155px;
  background: url(../img/banner-img.png) no-repeat center;
  margin-top: 20px;
  border-radius: 6px;
  position: relative;
  @include oddswidth;

  .socket-update {
    position: absolute;
    top: 5px;
    right: 7px;
    padding: 6px;
    background-color: rgba($white, 0.15);
    border-radius: 3px;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: red;
    }
  }

  .odds-legends {
    position: absolute;
    bottom: 5px;
    right: 7px;
    background-color: rgba($white, 0.45);

    @include media-breakpoint-down(sm) {
      right: 5px;
      left: 5px;
      border-radius: 10px;
      padding: 0 10px;
    }
  }

  &.scoreboard {
    background: #000;
    color: $white;
    text-align: center;
    line-height: 1.2;
    display: flex;
    align-items: center;

    .inner-scoreboard {
      flex: 1;
    }

    .league-name {
      font-size: 14px;
      font-weight: $font-weight-normal;
    }

    .event-name {
      font-size: 16px;
      margin-top: 5px;
      font-weight: $font-weight-normal;
    }
  }

  .score-list {
    @include flexwrap;
    max-width: 520px;
    margin: 25px auto 0;

    &+.score-list {
      margin-top: 5px;
    }

    .event-name-abbr {
      font-size: 21px;
      line-height: 1.2;
    }

    .event-name-abbr-sm {
      font-size: 16px;
      line-height: 1.2;
    }

    .event-over {
      font-size: 12px;
      color: rgba($white, 0.5);
    }

    .cell {
      text-align: right;
      padding-right: 15px;

      &:first-child {
        padding-left: 15px;
        padding-right: 0;
        text-align: left;
      }

      &.vs {
        padding: 5px 10px;
        flex: 0 0 34px;
        max-width: 34px;

        @include media-breakpoint-down(sm) {
          color: #ffffff !important;
        }
      }

      &.gameSequence {
        flex: 0 0 40%;
        max-width: 40%;
        @include flexwrap;
      }
    }
  }
}

.odds-padding-header-row {
  height: 75px;
  line-height: 20px;
  border-style: dashed;
  border-color: var(--main-color);
  padding: 3px 3px !important;
  display: table-cell !important;
  color: black;
  font-size: 14px;
  font-weight: bold;

  @include media-breakpoint-down(sm) {
    height: 70px;
    line-height: 14px;
    font-size: 10px;
    padding: 10px 5px !important;
  }

  border-radius: 5px;

  &.live-match-status {
    margin-left: 5px;
    margin-right: 5px;
  }

  &.w-42 {
    width: 42%;

    @include media-breakpoint-down(sm) {
      width: 38%;
    }

    p {
      @include media-breakpoint-down(sm) {
        font-size: 10px;
        margin-top: -8px;
      }
    }
  }

  &.w-85 {
    width: 85%;
    margin-right: 5px;

    @include media-breakpoint-down(sm) {
      width: 78%;
      padding: 20px 5px !important;
    }

    p {
      @include media-breakpoint-down(sm) {
        font-size: 10px;
        margin-top: -8px;
      }
    }
  }

  &.w-14 {
    width: 14%;

    @include media-breakpoint-down(sm) {
      width: 20%;
    }

    .commentary_status {
      background-color: var(--main-color);
      color: $white;
      text-align: center;
      min-height: 62px;
      display: flex;

      @include media-breakpoint-down(sm) {
        margin-top: -8px;
        min-height: 57px;
      }

      h1 {
        font-size: 1.3rem;
        width: 100%;

        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }
  }
}

.odds-padding-row {
  @include clearfix;
  @include oddswidth;
  padding: 5px;

  &.heading {
    padding: 25px 0 17px;

    .small {
      line-height: 1.2;
      color: $primary;
    }

    .title {
      font-weight: $font-weight-medium;
      font-size: 24px;
      line-height: 1.2;
      margin: 0;
    }

    .back-btn {
      font-size: 10px;
      width: 24px;
      // height: 24px;
      height: 52px;
      border-radius: 3px;
      background-color: rgba(black, 0.1);
      float: left;
      text-align: center;
      // padding: 8px 0;
      padding: 21px 0;
      margin: 0 10px 0 0;
      cursor: pointer;
    }

    .timestamp {
      font-size: 11px;
      line-height: 1.2;
      color: rgba(black, 0.7);
      cursor: default;

      i {
        vertical-align: -1px;
        margin-right: 4px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 15px 0;
  }
}

.odds-filter-pills {
  text-transform: uppercase;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  justify-content: center;

  >span {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.3;
    background-color: var(--main-color);
    color: #fff;
    padding: 8px 20px;
    margin-left: 10px;
    min-width: fit-content;
    border-radius: 35px;
    text-transform: uppercase;

    display: inline-block !important;
    margin-top: 4px;
    cursor: pointer;

    i {
      font-size: 18px;
      min-width: fit-content;
      display: inline-block !important;
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      cursor: default;
      background: white !important;
      color: $primary !important;
    }

    @include media-breakpoint-down(sm) {
      font-size: 10px;
      letter-spacing: 0px;
      padding: 8px 11px;
      margin-left: 5px;
    }
  }
}

.mobile-menu-option {
  margin: 10px 0px;

  .mobile-odds-filter-pills {
    text-transform: uppercase;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    justify-content: center;
    min-height: 50px;
    padding-top: 5px;

    span {
      color: var(--main-color);
      padding: 0px 15px;
      font-size: 16px;
      font-weight: 600;

      &.active {
        background-color: var(--main-color);
        color: white;
        border-radius: 19px;
        padding: 5px 15px;
      }
    }
  }
}

.mobile-sports-list {
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.odds-legends {
  float: right;
  // height: 24px;
  // width: 285px;
  border-radius: 18px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 3px 0;
  @include flexwrap;
  font-size: 11px;
  white-space: nowrap;
  font-weight: $font-weight-medium;
  padding: 0 15px;

  .cell {
    padding: 5px;
  }

  i {
    display: inline-block;
    vertical-align: -1px;
    margin-right: 4px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $gray-999;

    &.green {
      background-color: $green;
    }

    &.yellow {
      background-color: $yellow;
    }

    &.red {
      background-color: $red;
    }

    &.lay {
      background-color: $lay-bg;
    }
  }
}

.game-event-list {
  margin-bottom: 20px;
  @include oddswidth;

  &.m-t-20 {
    margin-top: 20px;
  }

  .sport-title {
    @include clearfix;
    cursor: pointer;
    font-size: 14px;
    margin: 0 0 10px;
    background-color: rgba($black, 0.5);
    // background-color: $gray-999;
    font-weight: $font-weight-normal;
    padding: 11px 15px;
    color: $white;

    &:first-child {
      margin-top: 0;
    }

    i {
      float: right;
      font-size: 15px;
      pointer-events: none;
    }
  }

  .sport-title-collapsing {
    transition: none !important;

    .game-event-item {
      margin-top: 0;
      margin-bottom: 10px;
    }

    &.collapsing {
      -webkit-transition: none !important;
      transition: none !important;
    }
  }
}

.game-event-item {
  // height: 78px;
  position: relative;
  border-radius: 4px;
  border: 1px solid $border-color;
  background-color: $white;
  margin-top: 10px;
  @include flexwrap;
  color: $gray-27;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  &:first-child {
    margin-top: 0;
  }

  .game-status-suspended {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;

    span {
      flex: 1 1;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      min-height: 40px;
    }
  }

  .small {
    font-size: 12px;
    line-height: 1.2;
    // color: $primary;
    color: #000;
    cursor: default;
  }

  .event-details {
    text-transform: uppercase;
    padding: 11px 15px;
    flex: 0 0 calc(100% - 200px);
    max-width: calc(100% - 200px);
    min-width: calc(100% - 200px);

    @media (max-width: 1080px) {
      flex: 0 0 calc(100% - 200px);
      max-width: calc(100% - 200px);
      min-width: calc(100% - 200px);
    }

    @include media-breakpoint-down(sm) {
      padding: 11px 15px 5px;
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
    }

    .title {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 2px;
      font-weight: $font-weight-medium;
      // color: $primary;
      color: var(--secondary-color);
      text-transform: uppercase;
      cursor: pointer;
      font-weight: bold;
      letter-spacing: 1px;
      @include text-truncate;
    }

    .timestamp {
      font-size: 11px;
      line-height: 1.2;
      color: #9398a0;
      cursor: default;

      i {
        vertical-align: -1px;
        margin-right: 4px;
      }
    }
  }

  .last-cell.betitem {
    background-color: var(--secondary-color);
    color: $white;
    flex: 0 0 200px;
    max-width: 200px;
    min-width: 200px;

    @media (max-width: 1080px) {
      flex: 0 0 200px;
      max-width: 200px;
      min-width: 200px;

      .mrkt-lbl {
        text-transform: uppercase;
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
      padding: 5px 0 8px;

      .mrkt-lbl {
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }

  .last-cell {
    flex: 0 0 80px;
    max-width: 80px;
    min-width: 80px;
    @include flexwrap;
    align-items: center;
    text-align: center;
    cursor: pointer;

    >a {
      font-size: 12px;
      color: $primary;
    }

    @media (max-width: 1080px) {
      flex: 0 0 50px;
      max-width: 50px;
      min-width: 50px;

      .mrkt-lbl {
        text-transform: uppercase;
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
      padding: 5px 0 8px;

      .mrkt-lbl {
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }

  .cell {
    flex: 0 0 320px;
    max-width: 320px;
    min-width: 320px;

    @media (max-width: 1080px) {
      flex: 0 0 300px;
      max-width: 300px;
      min-width: 300px;
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 100%;
    }

    // @include flexwrap;
    align-items: center;
    flex-direction: column;

    .row-cell {
      @include flexwrap;
      // flex: 1;
      flex-grow: 1;
      align-items: center;
      margin: 20px 0;

      .market-label {
        text-align: center;
        font-size: 10px;
        border-left: solid 1px $border-color;
        color: $border-color;

        &:first-child {
          border-left: none;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 0 15px;
      }

      &.heading {

        // 767
        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    position: relative;

    .game-status-suspended {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;

      span {
        flex: 1 1;
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        min-height: 40px;

        &:not(.template) {
          position: static;
        }
      }
    }

    // .market-label {
    //     text-align: center;
    //     font-size: 12px;
    //     color: rgba($gray-27, .6);
    //     border-left: solid 1px rgba($gray-27, .3);
    //     height: 20px;
    //     &:first-child {
    //         border-left: none;
    //     }
    // }

    .odd-trigger,
    .odd-trigger-inner {
      height: 36px;
      // margin-left: 8px;
      // border-radius: 4px;
      color: $white;
      text-align: center;
      font-size: 14px;
      line-height: 1.1;
      font-weight: $font-weight-medium;
      padding: 2px 0;
      @include flexwrap;
      flex-wrap: nowrap;
      align-items: center;
      cursor: pointer;
      border-left: solid 1px rgba($white, 1);

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &:first-child {
        margin-left: 0;
      }

      &.vslign {
        padding-top: 6px;
      }

      .btm {
        padding-top: 0;
        font-size: 10px;
        color: rgba(black, 0.5);
        font-weight: $font-weight-normal;
      }
    }

    .odd-trigger {
      justify-content: center;
      overflow: hidden;

      .odd-trigger-inner {
        align-items: center;
        font-size: 13px;

        &.disabled {
          // background-color: $gray-999 !important;
          opacity: 0.5;
          cursor: not-allowed;
          user-select: none;
        }

        &.green {
          background-color: $green;
        }

        &.red {
          background-color: $red;
        }

        &.lay {
          background-color: $lay-bg;
        }

        &.back {
          background-color: $back-bg;
        }

        &.yellow {
          @include SelectedOdd;
        }

        @include flexwrap;
        flex-direction: column;

        .price {
          line-height: 1;
          margin-top: 5px;
        }

        .price_volume {
          font-weight: $font-weight-normal;
          font-size: 10px;
          line-height: 1;
          opacity: 0.7;
        }
      }

      &:first-child {
        .odd-trigger-inner:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &:last-child {
        .odd-trigger-inner:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &.heading {
    font-size: 12px;
    height: auto;
    // border-radius: 0;
    // border-color: rgba($gray-27, .3);
    background-color: #e8eaef;
    margin-top: 0;

    .row-cell {
      margin: 0;
    }

    .event-details {
      padding: 2px 15px;
    }

    .market-label {
      text-align: center;
      font-size: 12px;
      border-left: solid 1px $border-color;
      margin: 2px 0;
      color: $body-color !important;

      &:first-child {
        border-left: none;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;

      &+.game-event-item {
        margin-top: 0;
      }
    }
  }

  &.mobile-view {
    @include media-breakpoint-down(sm) {
      box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

      .event-details {
        padding: 10px 5px 5px 10px;
        flex: 0 0 60%;
        max-width: 60%;
        min-width: 60%;

        .title {
          font-size: 14px;
        }
      }

      .last-cell.betitem {
        flex: 0 0 40%;
        max-width: 40%;
        min-width: 40%;
        font-weight: 600;
        padding: 5px 0px 5px;
        background-color: transparent;
        // color: #7b68cd;
        text-transform: uppercase;
      }
    }
  }
}

.mobile-game-event-item {
  position: relative;
  background-color: white;
  padding: 5px 15px;
  border-bottom: 1px solid #e6e6e6;

  &:first-child {
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    padding: 10px 20px;
  }

  .date-month {
    background-color: var(--main-color);
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;

    span {
      display: block;
      font-size: 10px;
    }
  }

  .live-tv {
    text-align: center;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    font-size: 10px;
    font-weight: 600;
    margin-top: 5px;
  }

  .league-name {
    color: #999999;
    font-weight: 500;
    font-size: 12px;

    .is-sport-name {
      display: inline;
      font-size: 10px;
    }
  }

  .event-name {
    color: var(--main-color);
    font-weight: 600;
  }

  .event-time {
    color: #999999;
    font-weight: 500;
    font-size: 12px;

    .markets {
      color: var(--main-color);
    }
  }

  .game-status-suspended {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;

    span {
      flex: 1 1;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      min-height: 40px;
    }
  }
}

.layback-odd {
  background-color: $white;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;

  .game-betlock {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;

    span {
      flex: 1 1;
      text-align: center;
    }
  }

  .heading {
    background-color: var(--main-color);
    color: $white;
    text-transform: uppercase;
    @include flexwrap;

    // font-size: 14px;
    .first-cell {
      flex: 0 0 345px;
      max-width: 345px;
      padding: 10px 15px;

      @include media-breakpoint-down(sm) {
        flex: 0 0 150px;
        max-width: 150px;
      }
    }

    .last-cell {
      text-align: center;
      flex: 0 0 245px;
      max-width: 245px;
      padding: 10px 15px;

      @include media-breakpoint-down(sm) {
        flex: 0 0 80px;
        max-width: 80px;
      }
    }

    .cell {
      font-weight: $font-weight-medium;
      @include flexwrap;
      text-align: center;
      max-width: 121px !important;

      .back-odd,
      .lay-odd {
        padding: 10px 0;

        @include media-breakpoint-down(sm) {
          padding: 55px 0px 0px 0px;
        }

        // border-bottom: solid 1px rgba($black, .3);
      }

      // .back-odd {
      //     background-color: $back-bg;
      // }
      // .lay-odd {
      //     background-color: $lay-bg;
      // }
      .light {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    // & + .layback-row {
    //     .cell, .first-cell {
    //         border-top: none;
    //     }
    // }
  }

  .layback-row {
    @include flexwrap;
    font-weight: $font-weight-medium;
    font-size: 15px;
    line-height: 1.2;
    position: relative;

    .game-status {
      position: absolute;
      left: 345px;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      width: 28.5%;

      span {
        flex: 1;
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        left: 150px;
      }
    }

    .first-cell {
      flex: 0 0 345px;
      max-width: 345px;
      padding: 0 15px;
      border-top: solid 1px rgba($black, 0.3);
      align-items: center;
      display: flex;
      min-height: 50px;

      @include media-breakpoint-down(sm) {
        flex: 0 0 150px;
        max-width: 150px;
      }

      &+.cell {
        border-left: none;
      }
    }

    .last-cell {
      text-align: center;
      flex: 0 0 245px;
      max-width: 245px;
      padding: 0 15px;
      border-top: solid 1px rgba($black, 0.3);
      align-items: center;
      display: flex;

      // flex-direction: column;
      span {
        display: block;
        font-weight: $font-weight-normal;

        &.winloss-1 {
          color: #267531 !important;
        }

        &.winloss-0,
        &.winloss--0 {
          color: $gray-999;
        }

        &.winloss--1 {
          color: $red;
        }
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 80px;
        max-width: 80px;
      }

      &+.cell {
        border-left: none;
      }
    }

    .cell {
      @include flexwrap;
      text-align: center;
      border-top: solid 1px rgba($black, 0.3);
      border-left: solid 1px rgba($white, 0.5);
      max-width: 121px !important;

      .odds-inner-toggle {
        user-select: none;
        padding: 10px 0;
        border-left: solid 1px rgba($white, 0.5);
        cursor: pointer;

        //max-width: calc(100% / 3);
        @include media-breakpoint-down(sm) {
          max-width: calc(100%);
        }
      }

      .hidebetodds {
        height: 50px;
      }

      &.back-odd {
        background-color: $back-bg;
        background-color: $back-ultra-light-bg;

        &.disabled {
          // filter: grayscale(100%);
          opacity: 0.5;
          cursor: not-allowed;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          }
        }

        >*:nth-child(1) {
          order: 3;
          background-color: $back-bg;

          &.bookmaker_odd {
            background-color: #728df0a3 !important;
          }

          &.yellow {
            @include SelectedOdd;
          }
        }

        >*:nth-child(2) {
          order: 2;
          background-color: $back-light-bg;
          cursor: default;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        >*:nth-child(3) {
          order: 1;
          background-color: $back-ultra-light-bg;
          cursor: default;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        >*:last-child {
          border-left: none;
        }
      }

      &.lay-odd {
        background-color: $lay-bg;
        background-color: $lay-ultra-light-bg;

        >*:nth-child(1) {
          order: 1;
          background-color: $lay-bg;

          &.bookmaker_odd {
            background-color: #fad6aa !important;
          }

          &.yellow {
            @include SelectedOdd;
          }
        }

        >*:nth-child(2) {
          order: 2;
          background-color: $lay-light-bg;
          cursor: default;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        >*:nth-child(3) {
          order: 3;
          background-color: $lay-ultra-light-bg;
          cursor: default;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &.disabled {
          // filter: grayscale(100%);
          opacity: 0.5;
          cursor: not-allowed;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          }
        }

        >*:first-child {
          border-left: none;
        }
      }

      .price_volume {
        font-weight: $font-weight-normal;
        font-size: 11px;
        line-height: 1.2;
      }
    }

    &.favorite_team {
      .first-cell {
        background-color: green;
        color: #ffffff;
      }

      .lay-odd,
      .back-odd {
        color: black;
      }
    }
  }
}

.odd-market-collapse {
  margin-bottom: 10px;
  background-color: rgba($white, 1);
  border-radius: 6px;
  overflow: hidden;
  line-height: 1.45;
  color: $gray-27;

  .heading {
    padding: 10px 15px;
    cursor: pointer;

    .icn {
      pointer-events: none;
      float: right;
      font-size: 16px;
      color: rgba(black, 0.5);
      padding: 3px 0;
    }
  }

  .odd-market-body {
    // @include flexwrap;
    background-color: rgba(#999, 0.4);
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 6px 6px;
    padding: 0.5px;
    overflow: hidden;

    .cell {
      flex: 1 1 calc(100% / 3 - 1px);
      // max-width: calc(100% / 3 - 1px);
      padding: 15px;
      margin: 0.5px;
      background-color: $white;
      display: flex;
      align-items: center;

      .text-cell {
        // max-width: calc(100% - 62px);
        // line-height: 1.75;
        line-height: 1.2;
        padding-right: 10px;
        flex: 1;
      }

      .odd-market-last {
        float: right;
        flex: 1 1 62px;
        max-width: 62px;
      }

      .odd-market-btn {
        display: block;
        height: 24px;
        width: 62px;
        background-color: $back-bg;
        border-radius: 3px;
        text-align: center;
        font-size: 12px;
        color: $white;
        padding: 4px 0;

        &.disabled {
          // background-color: $gray-999 !important;
          opacity: 0.5;
          cursor: not-allowed;
          user-select: none;
        }

        &.green {
          background-color: $green;
        }

        &.red {
          background-color: $red;
        }

        &.lay {
          background-color: $lay-bg;
        }

        &.yellow {
          @include SelectedOdd;
        }
      }

      position: relative;

      .game-status-suspended {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 400;
        justify-content: center;
        cursor: default;

        span {
          flex: 1 1;
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          min-height: 40px;

          &:not(.template) {
            position: static;
          }
        }
      }
    }
  }

  &.active {
    .heading {
      background-color: rgba(#999, 0.4);
    }
  }
}

.odd-market-row {
  @include flexwrap;
  width: 100%;

  .odd-market-line {
    background-color: $white;
    padding: 10px 15px;
    margin: 0.5px;

    &.odd-btn {
      cursor: pointer;

      &.yellow {
        background-color: $back-bg;
        color: $white;
        @include SelectedOdd;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        user-select: none;
      }
    }
  }

  .odd-market-btn {
    text-align: center;
    background-color: $white;
    padding: 10px 15px;
    margin: 0.5px;
    font-size: 12px;
    color: $white !important;
    background-color: $gray-999;
    line-height: 1.7;

    &.disabled {
      // background-color: $gray-999 !important;
      opacity: 0.5;
      cursor: not-allowed;
      user-select: none;
    }

    &.green {
      background-color: $green;
    }

    &.red {
      background-color: $red;
    }

    &.lay {
      background-color: $lay-bg;
    }

    &.yellow {
      @include SelectedOdd;
    }
  }

  &.head {
    .odd-market-line {
      text-align: center;
      padding: 0 15px;
      font-size: 12px;
      line-height: 1.9;
    }
  }

  position: relative;

  .game-status-suspended {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: center;
    cursor: default;

    span {
      flex: 1 1;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      min-height: 40px;

      &:not(.template) {
        position: static;
      }
    }
  }
}

.handicap-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .column {
    padding: 10px 15px;
    margin: 0.5px;
    font-size: 12px;
    background-color: $gray-999;
    color: $white !important;
    line-height: 1.7;
    cursor: pointer;
    @include flexwrap;

    &.disabled {
      // background-color: $gray-999 !important;
      opacity: 0.5;
      cursor: not-allowed;
      user-select: none;
    }

    >span {
      padding: 0 4px;

      &.line {
        text-align: right;
        color: rgba($white, 0.6) !important;
      }
    }

    &.green {
      background-color: $green;
    }

    &.red {
      background-color: $red;
    }

    &.lay {
      background-color: $lay-bg;
    }

    &.yellow {
      @include SelectedOdd;
    }

    &.head {
      padding: 0 15px;
      font-size: 14px;
      line-height: 1.9;
      background-color: $white;
      color: $gray-27 !important;
      text-align: center;
      cursor: default;
    }
  }
}

.fancy_mobile_view {
  @include media-breakpoint-down(sm) {
    overflow-x: scroll;
  }
}

.fancy-odd {
  background-color: $white;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;

  @include media-breakpoint-down(sm) {
    min-width: 600px;
  }

  .heading {
    background-color: var(--main-color);
    color: $white;
    text-transform: uppercase;
    @include flexwrap;
    height: 60px;

    .first-cell {
      flex: 0 0 245px;
      max-width: 245px;

      @include media-breakpoint-down(sm) {
        flex: 0 0 150px;
        max-width: 150px;
      }

      padding: 0 15px;
      @include flexwrap;
      align-items: center;

      p.min_max {
        font-size: 9px;
        margin-left: 10px;
        margin-top: 10px;

        @include media-breakpoint-down(sm) {
          display: contents;
        }
      }

      p.min_max_fancy_web {
        font-size: 9px;
        margin-left: 10px;

        @include media-breakpoint-down(sm) {
          display: contents;
        }
      }
    }

    .cell {
      font-weight: $font-weight-medium;
      @include flexwrap;
      align-items: center;
      padding: 0px 0px 0px 25px;

      @include media-breakpoint-down(sm) {
        padding: 0px 0px 0px 25px;
      }
    }
  }

  .fancy-odd-row {
    @include flexwrap;
    font-weight: $font-weight-medium;
    font-size: 13px;
    line-height: 1.2;

    >* {
      border-top: solid 1px rgba($black, 0.2);
    }

    .first-cell {
      flex: 0 0 245px;
      max-width: 245px;

      @include media-breakpoint-down(sm) {
        flex: 0 0 150px;
        max-width: 150px;
      }

      @include flexwrap;
      align-items: center;
      padding: 0 15px;
      border-top: solid 1px rgba($black, 0.2);

      &+.cell {
        border-left: none;
        justify-content: flex-end;
      }
    }

    .cell {
      @include flexwrap;
      align-items: center;
      padding: 5px;

      .amount {
        text-align: center;

        .loss {
          color: red;
        }

        .profit {
          color: green;
        }
      }

      .odds-inner-toggle {
        user-select: none;
        background-color: $green;
        height: 36px;
        cursor: pointer;
        padding: 0 10px;
        max-width: 90px;
        text-align: center;
        color: black;
        border-radius: 4px;

        .price {
          line-height: 1.2;
          margin-top: 12px;
        }

        .volume {
          font-weight: bold;
          font-size: 10px;
          line-height: 1;
          opacity: 0.7;
        }

        &.green {
          background-color: $green;
        }

        &.red {
          background-color: $red;
        }

        &.back {
          background-color: $lay-bg;
        }

        &.lay {
          background-color: $back-bg;
        }

        &.yellow {
          @include SelectedOdd;
        }
      }
    }

    position: relative;

    .fancy-status-cell {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 245px;
      background-color: rgba($black, 0.5);
      z-index: 1;
      @include flexwrap;
      align-items: center;
      align-self: center;
      text-align: center;
      font-size: 18px;
      color: $white;
      font-weight: $font-weight-normal;

      @include media-breakpoint-down(sm) {
        left: 150px;
        right: 35%;
      }
    }
  }
}

.odd-box-heading {
  font-size: 18px;
  font-weight: $font-weight-medium;
  line-height: 1.2;
  padding: 8px 0;
  color: $gray-30;
}

.highlight {
  background-color: blue !important;
}

.is-live-lbl {
  float: left;
  text-align: center;
  display: block;
  color: $gray-27;
  background-color: $yellow;
  padding: 2px 5px;
  border-radius: 2px;
  margin-top: -4px;
  width: 50px;
  white-space: nowrap;
}

.is-sport-name {
  text-align: center;
  display: block;
  color: $gray-27;
  background-color: $yellow;
  padding: 2px 5px;
  border-radius: 2px;
  margin-top: -4px;
  margin-left: 5px;
  white-space: nowrap;
}

.btn-primary-back {
  width: 100%;
  background-color: var(--main-color);
  color: $white !important;
  text-transform: uppercase;
}

.match_live {
  min-height: 30px;
  margin-top: 20px;

  @include media-breakpoint-down(sm) {
    margin-top: 0px;
    min-height: 30px;
    background-color: white;
  }

  span.commentary {
    color: #41337d;
    font-weight: 900;
  }

  strong {
    @include media-breakpoint-down(sm) {
      float: left;
      color: #41337d;
      font-weight: 900;
      font-size: 14px;
    }
  }

  .date_time {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
      font-weight: 900;
      float: right;
      margin-right: 5px;
      color: gray;
    }
  }

  .match_commentary {
    float: right;
  }

  .mobile_match_commentary {
    float: right;
    display: flow-root;
    width: 100%;
  }

  button.commentary_button {
    float: right;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.78;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 8px 20px;
    border-radius: 14px;
    border: solid 1px #e91e1e;
    background-image: linear-gradient(to bottom, #e91e1e, #e5c211);

    &:hover,
    &:active {
      border: solid 1px #e91e1e;
      background-image: linear-gradient(to bottom, #e91e1e, #e5c211);
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
}

.score_dashboard {
  margin: 0px 0px 0px 0px;
  border-radius: 4px;
  min-height: 95px;
  border: solid 1px #e7e2ff;
  background-color: #f7f5ff;

  &.hide {
    @include media-breakpoint-down(sm) {
      min-height: 0px;
    }
  }

  &.tabshow {
    @include media-breakpoint-down(sm) {
      display: block;
      border-radius: 5px;
      box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);
      background: url(../../Assets/img/score_bg.jpg) center center no-repeat;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff !important;
    }

    &::before {
      @include media-breakpoint-down(sm) {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.582);
      }
    }

    .match_name {
      @include media-breakpoint-down(sm) {
        color: #ffffff;
      }
    }
  }

  .show {
    display: contents;

    @include media-breakpoint-down(sm) {
      display: block;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);
      background-image: linear-gradient(to bottom,
          rgba(255, 255, 255, 0.5) 80%,
          #ebe7ff),
        linear-gradient(to bottom, #ffffff, #ffffff);
    }
  }

  b.col-title {
    color: #7b68cd;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
  }

  @include media-breakpoint-down(sm) {
    margin: 0px 0px 0px 0px;
    background-color: transparent;
    border: solid 0px transparent;
  }

  iframe {
    width: 100%;
    min-height: 156px;
    border: 0px solid transparent;

    @include media-breakpoint-down(sm) {
      min-height: 156px;
    }
  }

  .scor_images {
    height: 20px;
    width: 20px;
  }

  .scoreboard {
    width: 100%;
    text-align: center;
    color: lightgray;

    @include media-breakpoint-down(sm) {
      padding: 5px;
      margin: 0px;
      width: 100%;
    }
  }

  .live_status {
    padding: 5px 0px 0px 0px;
    width: 100%;
    display: flex;
    opacity: 0.7;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;

    @include media-breakpoint-down(sm) {
      color: #ffffff;
    }

    .dot {
      width: 10px;
      height: 10px;
      background-color: red;
      border-radius: 50%;
      margin: 1px 5px 0px -5px;
    }
  }

  .team_score {
    text-align: center;

    .vs {
      font-family: Roboto;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.7;
      letter-spacing: normal;
      text-align: center;
      color: #dddddd;
      float: left;
      width: 100%;
      margin: 10px 0px 10px 0px;

      @include media-breakpoint-down(sm) {
        padding-left: 0%;
        color: #ffffff;
        padding-top: 35%;
        margin: 0px;
      }
    }

    .team_name {
      strong {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #000000;

        @include media-breakpoint-down(sm) {
          color: #ffffff;
        }
      }
    }
  }

  .match_score {
    padding: 10px;
    background-color: #47388c;

    &::after {
      width: 0;
      height: 0;
      border-top: 100px solid red;
      border-right: 100px solid transparent;
    }

    &.scorecard_match_score {
      padding: 0px;
    }

    table {
      margin-bottom: 0px;
      font-size: 10px;
      font-weight: bold;

      thead {
        tr {
          background-color: #47388c;
          color: white;

          th {
            padding: 5px;
            text-align: center;
            border-top: 1px solid #c8c9cb;
            border-bottom: 1px solid #c8c9cb;

            &:first-child {
              text-align: left;
              width: 60%;
            }
          }
        }
      }

      tbody {
        background-color: #f7f5ff;

        tr {
          border-bottom: 1px solid lightgray;

          td {
            padding: 5px;
            text-align: center;
            border-top: 0px solid transparent;

            &:first-child {
              text-align: left;
              width: 60%;
            }
          }
        }
      }
    }

    .match_name {
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
    }

    .player_name {
      margin: 5px 0px 10px 0px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      strong {
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-right: 25px;

        @include media-breakpoint-down(sm) {
          margin-right: 10px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .crr_rrr {
      .line {
        opacity: 0.3;
        color: #dedede;
      }

      opacity: 0.9;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    .current_over {
      margin: 10px 0px 0px 0px;

      .over_boll {
        width: 24px;
        height: 24px;
        padding: 4px 1px;
        border-radius: 12px;
        border: solid 1px #ffffff;
        background-color: #ffffff;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #47388c;
        float: left;

        &.wicket {
          border-radius: 15px;
          color: white;
          border: solid 1px #ffffff;
          background-color: #e10000;
        }

        &.w-1 {
          margin-left: 48%;
        }

        &.w-2:first-child {
          margin-left: 40%;
        }

        &.w-3:first-child {
          margin-left: 35%;

          @include media-breakpoint-down(sm) {
            margin-left: 31%;
          }
        }

        &.w-4:first-child {
          margin-left: 28%;

          @include media-breakpoint-down(sm) {
            margin-left: 24%;
          }
        }

        &.w-5:first-child {
          margin-left: 24%;

          @include media-breakpoint-down(sm) {
            margin-left: 18%;
          }
        }

        &.w-6:first-child {
          margin-left: 18%;

          @include media-breakpoint-down(sm) {
            margin-left: 12%;
          }
        }

        &.w-7:first-child {
          margin-left: 13%;

          @include media-breakpoint-down(sm) {
            margin-left: 5%;
          }
        }

        &.w-8:first-child {
          margin-left: 8%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-8:last-child {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-9:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-9:nth-last-child(3),
        &.w-9:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-10:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-10:nth-last-child(5),
        &.w-10:nth-last-child(3),
        &.w-10:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-11:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-11:nth-last-child(7),
        &.w-11:nth-last-child(5),
        &.w-11:nth-last-child(3),
        &.w-11:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-12:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-12:nth-last-child(9),
        &.w-12:nth-last-child(7),
        &.w-12:nth-last-child(5),
        &.w-12:nth-last-child(3),
        &.w-12:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }
      }

      .over_boll_line {
        float: left;
        width: 25px;
        height: 6px;
        margin: 8px -2px;
        border-radius: 8.5px;
        background-color: #ffffff;

        @include media-breakpoint-down(sm) {
          width: 23px;
        }

        &.w-8:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-9:nth-last-child(4),
        &.w-9:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-10:nth-last-child(6),
        &.w-10:nth-last-child(4),
        &.w-10:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-11:nth-last-child(8),
        &.w-11:nth-last-child(6),
        &.w-11:nth-last-child(4),
        &.w-11:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-12:nth-last-child(10),
        &.w-12:nth-last-child(8),
        &.w-12:nth-last-child(6),
        &.w-12:nth-last-child(4),
        &.w-12:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

.match_score_2 {
  padding: 0px;
  font-weight: bold;

  @include media-breakpoint-down(sm) {
    color: #ffffff;
  }

  &::after {
    width: 0;
    height: 0;
    border-top: 100px solid red;
    border-right: 100px solid transparent;
  }

  table {
    margin-bottom: 0px;
    font-size: 10px;
    font-weight: bold;

    thead {
      tr {
        background-color: #47388c;
        color: white;

        th {
          padding: 5px;
          text-align: center;
          border-top: 1px solid #c8c9cb;
          border-bottom: 1px solid #c8c9cb;

          &:first-child {
            text-align: left;
            width: 60%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px;
          text-align: center;
          border-top: 0px solid transparent;

          @include media-breakpoint-down(sm) {
            color: white;
          }

          &:first-child {
            text-align: left;
            width: 60%;
          }
        }
      }
    }
  }

  .match_name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .player_name {
    margin: 5px 0px 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    strong {
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-right: 25px;

      @include media-breakpoint-down(sm) {
        margin-right: 10px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .over-row {
    background-color: #47388c;

    @include media-breakpoint-down(sm) {
      background-color: transparent;
    }

    .crr_rrr {
      .line {
        opacity: 0.3;
        color: #dedede;
      }

      padding: 5px;
      opacity: 0.9;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    .current_over {
      margin: 5px 0px 5px 0px;

      .over_boll {
        width: 25px;
        height: 25px;
        padding: 6px 0px;
        border-radius: 12px;
        border: solid 1px #ffffff;
        background-color: #ffffff;
        font-family: Roboto;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #47388c;
        float: left;

        &.wicket {
          border-radius: 15px;
          color: white;
          border: solid 1px #ffffff;
          background-color: #e10000;
        }

        &.w-1 {
          margin-left: 48%;
        }

        &.w-2:first-child {
          margin-left: 40%;
        }

        &.w-3:first-child {
          margin-left: 35%;

          @include media-breakpoint-down(sm) {
            margin-left: 31%;
          }
        }

        &.w-4:first-child {
          margin-left: 28%;

          @include media-breakpoint-down(sm) {
            margin-left: 24%;
          }
        }

        &.w-5:first-child {
          margin-left: 24%;

          @include media-breakpoint-down(sm) {
            margin-left: 18%;
          }
        }

        &.w-6:first-child {
          margin-left: 18%;

          @include media-breakpoint-down(sm) {
            margin-left: 12%;
          }
        }

        &.w-7:first-child {
          margin-left: 13%;

          @include media-breakpoint-down(sm) {
            margin-left: 5%;
          }
        }

        &.w-8:first-child {
          margin-left: 8%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-8:last-child {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-9:first-child {
          margin-left: 0%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-9:nth-last-child(3),
        &.w-9:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-10:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-10:nth-last-child(5),
        &.w-10:nth-last-child(3),
        &.w-10:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-11:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-11:nth-last-child(7),
        &.w-11:nth-last-child(5),
        &.w-11:nth-last-child(3),
        &.w-11:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }

        &.w-12:first-child {
          margin-left: 2%;

          @include media-breakpoint-down(sm) {
            margin-left: 6%;
          }
        }

        &.w-12:nth-last-child(9),
        &.w-12:nth-last-child(7),
        &.w-12:nth-last-child(5),
        &.w-12:nth-last-child(3),
        &.w-12:nth-last-child(1) {
          @include media-breakpoint-down(sm) {
            margin-top: 5px;
          }
        }
      }

      .over_boll_line {
        float: left;
        width: 25px;
        height: 6px;
        margin: 8px -2px;
        border-radius: 8.5px;
        background-color: #ffffff;

        @include media-breakpoint-down(sm) {
          width: 23px;
        }

        &.w-8:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-9:nth-last-child(4),
        &.w-9:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-10:nth-last-child(6),
        &.w-10:nth-last-child(4),
        &.w-10:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-11:nth-last-child(8),
        &.w-11:nth-last-child(6),
        &.w-11:nth-last-child(4),
        &.w-11:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        &.w-12:nth-last-child(10),
        &.w-12:nth-last-child(8),
        &.w-12:nth-last-child(6),
        &.w-12:nth-last-child(4),
        &.w-12:nth-last-child(2) {
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

.dis-block {
  display: block;
}

.mobile-view-declared {
  margin-top: 10px;

  b.col-title {
    color: #7b68cd;
    font-size: 18px;
    font-weight: 900;
  }

  .card {
    box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

    .card-body {
      table.declared_session {
        thead {
          tr {
            background-color: transparent !important;
            color: #a4a4a4;

            th {
              padding: 10px 5px;
              font-weight: 600;
              font-size: 13px;

              &.session_name {
                min-width: 110px;
              }
            }
          }
        }

        tfoot {
          tr {
            background-color: #eae5ff;

            td {
              color: #7b68cd;
              border-top: 0px solid transparent;
              font-weight: 600;
              font-size: 13px;
              padding: 5px;

              .loss {
                color: #f53133;
              }

              .profit {
                color: #008500;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-view-listofbet {
  margin-top: 10px;

  b.col-title {
    color: #7b68cd;
    font-size: 18px;
    font-weight: 900;
  }

  .card {
    box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

    .card-body {
      .betslip-tabs {
        border-radius: 3px;
        background-color: #e8e8e8;
        color: #a4a4a4;
        font-weight: 600;
        font-size: 13px;

        .active {
          background-color: transparent !important;
          color: #7b68cd;
        }
      }

      table.table-bets {
        thead {
          tr {
            th {
              padding: 10px 5px;
              font-weight: 600;
              font-size: 13px;
            }
          }
        }

        tbody {
          tr {
            td {
              color: #575757;
              font-weight: 600;
              font-size: 13px;
              border-top: 0px solid transparent;

              .back {
                float: left;
                width: 5px;
                height: 5px;
                display: block;
                background-color: #61acde;
                margin-top: 7px;
                margin-right: 2px;
              }

              .lay {
                float: left;
                width: 5px;
                height: 5px;
                display: block;
                background-color: #f6b771;
                margin-top: 8px;
                margin-right: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-view-market {
  margin-top: 10px;

  b.col-title {
    color: #7b68cd;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
  }

  span.sub-heading {
    color: #7b68cd;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
  }

  .switch {
    .switch-toggle {
      height: 16px;
      width: 37px;

      .switch-toggle--off::after,
      .switch-toggle--on::after {
        top: 1px;
        height: 14px;
        width: 14px;
      }
    }

    .switch--default {
      &::after {
        top: 1px;
        height: 14px;
        width: 14px;
      }
    }
  }

  .switch.switch--default>.switch-toggle.switch-toggle--on::after,
  .switch.switch--default>.switch-toggle.switch-toggle--off::after {
    top: 1px;
    height: 14px;
    width: 14px;
    border-radius: 12px;
    background: #ffffff !important;
    will-change: transform;
    transition: all 0.4s ease-out;
  }

  .card {
    box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

    .card-body {
      .mobile-view-layback-odd {
        .heading {
          text-transform: uppercase;
          display: flex;
          flex-wrap: wrap;
          color: #a4a4a4;

          .first-cell {
            flex: 0 0 46%;
            max-width: 46%;
            width: 46%;
          }

          .cell {
            flex: 0 0 18%;
            max-width: 18%;
            width: 18%;
            text-align: center;
            font-weight: 600;
            font-size: 12px;
            padding: 5px 0px;
          }
        }

        .game-betlock {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          color: #fff;
          display: flex;
          align-items: center;
          font-weight: 400;
          cursor: pointer;

          span {
            flex: 1 1;
            text-align: center;
          }
        }

        .layback-row {
          @include flexwrap;
          font-weight: $font-weight-medium;
          font-size: 15px;
          line-height: 1.2;
          position: relative;

          .game-status {
            position: absolute;
            left: 200px;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(black, 0.5);
            color: $white;
            display: flex;
            align-items: center;
            font-weight: $font-weight-normal;

            span {
              flex: 1;
              text-align: center;
            }

            @include media-breakpoint-down(sm) {
              left: 46.8%;
              right: 18.8%;
            }
          }

          .first-cell {
            flex: 0 0 46%;
            max-width: 46%;
            width: 46%;
            padding: 10px 10px;
          }

          .last-cell {
            text-align: center;
            flex: 0 0 18%;
            max-width: 18%;
            width: 18%;
            align-items: center;
            display: flex;

            span {
              display: block;
              font-weight: 600;
              font-size: 10px;

              &.winloss-1 {
                color: #267531 !important;
              }

              &.winloss-0,
              &.winloss--0 {
                color: $gray-999;
              }

              &.winloss--1 {
                color: $red;
              }
            }
          }

          .cell {
            @include flexwrap;
            text-align: center;
            flex: 0 0 18%;
            max-width: 18%;
            width: 18%;

            .odds-inner-toggle {
              user-select: none;
              padding: 5px 0;
              cursor: pointer;
              margin: 5px;
              border-radius: 5px;
            }

            .back-odd {
              background-color: $back-bg;

              &.disabled {
                // filter: grayscale(100%);
                opacity: 0.5;
                cursor: not-allowed;
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  top: 0;
                }
              }

              >*:nth-child(1) {
                order: 3;
                background-color: $back-bg;

                &.bookmaker_odd {
                  background-color: #728df0a3 !important;
                }

                &.yellow {
                  @include SelectedOdd;
                }
              }

              >*:nth-child(2) {
                order: 2;
                background-color: $back-light-bg;
                cursor: default;

                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }

              >*:nth-child(3) {
                order: 1;
                background-color: $back-ultra-light-bg;
                cursor: default;

                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }

              >*:last-child {
                border-left: none;
              }
            }

            .lay-odd {
              background-color: $lay-bg;
              background-color: $lay-ultra-light-bg;

              >*:nth-child(1) {
                order: 1;
                background-color: $lay-bg;

                &.bookmaker_odd {
                  background-color: #fad6aa !important;
                }

                &.yellow {
                  @include SelectedOdd;
                }
              }

              >*:nth-child(2) {
                order: 2;
                background-color: $lay-light-bg;
                cursor: default;

                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }

              >*:nth-child(3) {
                order: 3;
                background-color: $lay-ultra-light-bg;
                cursor: default;

                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }

              &.disabled {
                // filter: grayscale(100%);
                opacity: 0.5;
                cursor: not-allowed;
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  top: 0;
                }
              }

              >*:first-child {
                border-left: none;
              }
            }

            .price_volume {
              font-weight: $font-weight-normal;
              font-size: 11px;
              line-height: 1.2;
            }
          }

          &.favorite_team {
            .first-cell {
              background-color: green;
              color: #ffffff;
            }

            .lay-odd,
            .back-odd {
              color: black;
            }
          }
        }
      }
    }
  }
}

.mobile-view-fancy {
  margin-top: 10px;

  b.col-title {
    color: #7b68cd;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
  }

  span.sub-heading {
    color: #7b68cd;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
  }

  .card {
    box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

    .card-body {
      .fancy_mobile_view {
        .fancy-odd {
          .heading {
            background-color: transparent;
            color: #a4a4a4;
            font-weight: 600;
            font-size: 12px;
          }
        }

        .fancy-odd-row {
          @include flexwrap;
          font-weight: $font-weight-medium;
          font-size: 13px;
          line-height: 1.2;

          >* {
            border-top: solid 0px transparent;
          }

          .first-cell {
            font-size: 12px;

            @include media-breakpoint-down(sm) {
              flex: 0 0 150px;
              max-width: 150px;
            }

            @include flexwrap;
            align-items: center;
            padding: 0 5px;

            &+.cell {
              border-left: none;
              justify-content: flex-end;
            }
          }

          .cell {
            @include flexwrap;
            align-items: center;
            padding: 5px;

            .amount {
              text-align: center;

              .loss {
                color: red;
              }

              .profit {
                color: green;
              }
            }

            .odds-inner-toggle {
              user-select: none;
              background-color: $green;
              height: auto;
              cursor: pointer;
              padding: 5px 0px;
              max-width: 90px;
              text-align: center;
              color: black;
              border-radius: 4px;

              .price {
                line-height: 1.2;
                margin-top: 0px;
              }

              .volume {
                font-weight: bold;
                font-size: 10px;
                line-height: 1;
                opacity: 0.7;
              }

              &.green {
                background-color: $green;
              }

              &.red {
                background-color: $red;
              }

              &.back {
                background-color: $lay-bg;
              }

              &.lay {
                background-color: $back-bg;
              }

              &.yellow {
                @include SelectedOdd;
              }
            }
          }

          position: relative;

          .fancy-status-cell {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 245px;
            background-color: rgba($black, 0.5);
            z-index: 1;
            @include flexwrap;
            align-items: center;
            align-self: center;
            text-align: center;
            font-size: 18px;
            color: $white;
            font-weight: $font-weight-normal;

            @include media-breakpoint-down(sm) {
              left: 150px;
              right: 35%;
            }
          }
        }
      }
    }
  }
}

.mobile-view-liveTV {
  b.col-title {
    color: #7b68cd;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    margin-right: 5px;
  }

  iframe {
    border: 0px solid transparent;
    min-height: 225px;
    width: 100%;
    box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

    &.score1 {
      min-height: 180px;
      border: 0px solid transparent;
      width: 100%;
      box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .collapse {
    margin-top: 5px;

    &.show {
      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }

    .nav-tabs {
      margin-top: 0px;

      .nav-item {
        margin-top: 0px;
        min-width: 70px !important;
        padding: 0px;
        margin-right: 0px;

        .nav-link {
          background-color: #d4cdf2;
          color: #4c3e86;
          font-weight: 600;
          border: 1px solid #8f87a5;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          font-size: 12px;
          font-weight: 900;

          &.active {
            background-color: white;
          }
        }
      }
    }
  }
}

.commentary-live-tv {
  color: white !important;
  border: 0px solid transparent;
  background-color: #3a61a2;
  font-weight: 600;
  padding: 7px 15px;
  border-radius: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}

.match-detail-refresh {
  color: white !important;
  border: 0px solid transparent;
  background-color: #1fabb5;
  font-weight: 600;
  padding: 7px 15px;
  border-radius: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}

img.commentary_img {
  width: 105px;
  margin-top: -10px;
}

.new_score_board_live {
  border: 0px solid transparent;
  min-height: 150px;
  width: 102%;
  box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

  &.score1 {
    min-height: 180px;
  }
}

.desktop_new_score_board_live {
  border: 0px solid transparent;
  min-height: 192px;
  width: 100%;
  box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.2);

  &.score1 {
    min-height: 180px;
  }
}

.errorModal {
  z-index: 100 !important;
}

.modal-classes {
  top: 25% !important;

  .modal-header {
    padding: 6px;

    .modal-title {
      border-bottom: none;
      padding: 0rem 0rem 0rem 0rem !important;
      font-weight: 700 !important;
    }
  }

  .modal-body {
    padding: 0px;
    padding-top: 5px !important;
  }
}

.modalErrormsg {
  font-weight: 700 !important;
  color: red !important;
}


// new live report

.layback-odd-new {
  background-color: $white;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;
  border: 1px solid #C8C8C8;
  font-weight: 700;


  .heading {
    background-color: var(--secondary-color);
    color: $white;
    text-transform: uppercase;
    // @include flexwrap;

    .min_max_price {
      // color: #4ADA60;
      color: $white;
    }

  }

  .layback-row {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-top: 1px solid #C8C8C8;

    .winloss-0,
    .winloss--0 {
      color: $gray-999;
    }

    .winloss-1 {
      color: #267531 !important;
    }

    .loss {
      color: red;
    }

    .profit {
      color: green;
    }

    .winloss--1 {
      color: $red;
    }

    .book_btn {
      color: $white;
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
    }

    .mob_btn {
      font-size: 9px !important;
      padding: 7.5px 9px 4.5px !important;
    }

    .cell {


      .back-odd {
        background-color: $back-bg;
        background-color: $back-ultra-light-bg;
        padding: 0px;
        border-radius: 5px;
        height: 41px;
        cursor: pointer;
        font-size: 18px;

      }

      .lay-odd {
        background-color: $lay-bg;
        background-color: $lay-ultra-light-bg;
        padding: 0px;
        border-radius: 5px;
        height: 41px;
        cursor: pointer;
        font-size: 18px;
      }
    }


    .game-status-50 {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 50px;

      @include media-breakpoint-down(sm) {
        height: 37px;
        left: 0px;
      }

      span {
        flex: 1;
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }
      }




    }


    .game-status {
      position: absolute;
      left: 65%;
      right: 1%;
      margin-left: 7px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 50px;

      @include media-breakpoint-down(sm) {
        height: 37px;
        left: 64%;
      }

      span {
        flex: 1;
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }
      }




    }

    .game-status-odd {
      position: absolute;
      left: 65%;
      right: 18%;
      margin-left: 7px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 50px;

      @include media-breakpoint-down(sm) {
        height: 37px;
        left: 64%;
      }

      span {
        flex: 1;
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }


        .pad_lock {

          width: 32px;
          height: 30px;

          @include media-breakpoint-down(sm) {
            width: 20px;
            height: 20px;
          }
        }

      }




    }

    .game-status-lay {
      position: absolute;
      left: 81%;
      right: 1%;
      margin-left: 7px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 50px;

      @include media-breakpoint-down(sm) {
        height: 37px;
        left: 80%;
        right: 2%;
      }

      span {
        flex: 1;
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }


        .pad_lock {

          width: 32px;
          height: 30px;

          @include media-breakpoint-down(sm) {
            width: 20px;
            height: 20px;
          }
        }



      }


    }







    // .game-status-web {

    //   left: 48% !important;
    //   right: 1% !important;
    //   margin-left: 17px !important;
    //   height: 50px !important;

    // }
  }
}

.mneg5 {
  margin-top: -5px;
}

.h30 {
  height: 30px !important;
}

.h22 {
  height: 22px !important;
}


.p5 {
  padding: 5px !important;
}


.upcomming_fixture_heading {
  background-color: var(--secondary-color);
  border-top: 3px solid #485A6B;
  color: $white;
  overflow: hidden;
  // height: 75px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1px;
  }


  .row {
    height: 100%;

    .col .f-cell {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 400;
      padding-left: 34px;

      @include media-breakpoint-down(sm) {
        font-size: 11px;
      }
    }

    .trangle {
      // background-color: #505050;
      // width: 170px;
      // height: 180px;
      // top: -54px;
      // left: 0px;
      // transform: rotate(48deg);
      // position: absolute;
      background-color: #485A6B;
      width: 227px;
      height: 196px;
      top: -49px;
      left: -1px;
      transform: rotate(48deg);
      position: absolute;



      @include media-breakpoint-down(sm) {
        background-color: #485A6B;
        width: 170px;
        height: 180px;
        top: -59px;
        left: -72px;
        transform: rotate(48deg);
        position: absolute;


      }
    }
  }
}

.match_title {
  overflow: hidden;


  .row {
    background-color: var(--secondary-color);
    color: $white;
    padding: 7px;

    .day {
      justify-content: space-between;
      display: flex;
      font-weight: 500;
      font-size: 14px;

      .row {
        background: transparent;
      }



      @include media-breakpoint-down(sm) {
        font-size: 9px;
        display: flex;
        text-align: center;
        justify-content: initial;
      }

      i {
        font-size: 25px;

        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }

      .status-container {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          display: grid;
        }
      }

      .status {
        font-weight: 500;

        // i {
        //   font-size: 20px;

        //   @include media-breakpoint-down(sm) {
        //     font-size: 20px;
        //   }
        // }
      }

      .time {
        font-weight: 400;
        margin-left: 25px;

        @include media-breakpoint-down(sm) {
          margin-left: 0px;
        }

      }
    }

    .series {
      border-left: 2px solid $white;
      display: grid;

      .team_name {
        font-weight: 700;
        font-size: 15px;

        @include media-breakpoint-down(sm) {
          font-size: 9px;
        }
      }

      .league_name {
        font-weight: 400;
        font-size: 12px;

        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }
      }
    }
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.announcement {
  background-color: var(--main-color);
  color: $white;
  padding: 7px;

}


.match_score_board {
  border: solid 1px #c8c9cb;
  border-radius: 2px;

  .board_header {
    font-weight: 600;
    border-bottom: 1px solid;
    // background-color: linear-gradient(#262626, #262626);
    background-color: var(--main-color);
    color: #FAF9F6;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.2;
    position: relative;
    cursor: pointer;

    .col {
      padding: 8px 10px;

      span {
        float: left;
      }

      i {
        float: right;
      }
    }
  }

  .board_sub_header {
    background-color: var(--secondary-color);

    .col {
      text-transform: uppercase;
      text-align: center;
      padding: 5px 10px;
      // background-color: linear-gradient(#262626, #262626);
      // background-color: #FAF9F6;

      color: #FAF9F6;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        padding: 5px 10px;
        font-size: 10px;
      }

      &.active {
        background: var(--main-color);
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

}


.declared_market_new {
  .title {
    color: $white;
    background-color: var(--main-color);
    text-align: left;
    text-transform: uppercase;
    padding: 2px 10px;
    border-radius: 4px;

    .name {
      font-size: 12px;
      padding: 5px;
      cursor: pointer;
      font-weight: 400;

      @include media-breakpoint-down(sm) {
        padding: 3px;
        font-size: 10px;
      }
    }
  }

  .headings {
    font-size: 10px;
    padding: 5px;
    color: $white;
    font-weight: 400;
    background-color: var(--main-color);
    text-align: center;
  }


  .even {
    color: #000;
    background-color: $white;
  }

  .odd {
    color: #000;
    background-color: #ffffff54;
  }

  .body {
    font-size: 12px;
    padding: 5px;
    text-align: center;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
      padding: 3px;
      font-size: 10px;
    }

    button {
      font-size: 9px;
      padding: 0.25rem 0.3rem;

    }

    .loss {
      color: red;
    }

    .profit {
      color: #4fd21c;
    }
  }

  .declared_market_footer {
    padding: 5px;
    font-size: 12px;
    text-align: left;
    font-weight: 400;
    background-color: var(--main-color);
    color: $white;
    border-top: 1px solid #eee;
    border-radius: 4px;

    @include media-breakpoint-down(sm) {
      padding: 3px;
      font-size: 10px;
    }


    .loss {
      color: red;
    }

    .profit {
      color: #4fd21c;
    }
  }


}

.bg-new {
  background-color: var(--main-color);
  color: $white;
}

.declare-cover {
  .title {
    background-color: var(--secondary-color);
    color: $white;
    text-align: left;
    text-transform: uppercase;
    padding: 2px 10px;
    border-radius: 4px;

    .name {
      font-size: 12px;
      padding: 5px;
      cursor: pointer;
      font-weight: 400;

      @include media-breakpoint-down(sm) {
        padding: 3px;
        font-size: 10px;
      }


    }
  }
}


.declaredSessionShow {
  transition: .5s all ease;
  height: auto;
  display: block;
}

.declaredSessionHide {
  transition: .5s all ease;
  height: 0;
  display: none;
}

.modal-footer {
  &.justify-content-space-between {
    justify-content: space-between !important;
  }
}

.mneg26-mob16 {
  margin-top: -26px !important;

  @include media-breakpoint-down(sm) {
    margin-top: -16px !important;
  }
}



.all-market-details {
  display: inline-block;
  // float: right;

  .tv-icon {
    padding: 1px 9px;
    background-color: var(--secondary-color);
    clip-path: polygon(50% 0%, 100% 0, 100% 78%, 76% 78%, 100% 100%, 50% 100%, 0 100%, 25% 79%, 0 79%, 0 0);
  }

  @include media-breakpoint-down(sm) {
    // padding-right: 28px;
    float: left;
  }

  span {
    margin-left: 5px;
    margin-right: 5px;

    @include media-breakpoint-down(sm) {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  .mo {
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      font-size: 9px;
    }
  }

  .i {
    font-size: 16px;

    @include media-breakpoint-down(sm) {
      font-size: 9px;
    }
  }

  .market-numb {
    // background-image: url(../../Assets/img/msgBubble.png);
    background-size: cover !important;
    margin: 0px !important;
    text-align: center;
    padding: 4px 3px 8px 0px;
    background-color: var(--secondary-color);
    clip-path: polygon(0 0, 100% 0, 100% 82%, 74% 82%, 25% 83%, 0 100%, 0 100%);
    border-radius: 3px;



    .data {
      color: white;
    }

    @include media-breakpoint-down(sm) {
      padding: 2px 2px 6px 1px;
    }
  }

  .one_digit {
    padding: 3px 3px 5px 3px !important;

    @include media-breakpoint-down(sm) {
      padding: 0px 5px 3px 1px !important;
    }
  }

}