.contianer-wrap{
    margin-top: 20px;
    overflow: hidden;
    min-height: 500px;
    .table-primary{
        margin-top: 20px;
        a{
            color: $black;
        }
        .delete-row{
            border:1px solid $gray-999;
            font-size: 22px;
            border-radius: 2px;
            color: $black;
            text-align: center;
            i{
                vertical-align: -1px;
            }
        }
        .collapse{
            height: 65px !important;
            overflow: hidden;
            opacity: 0;
            min-height: 65px !important;
            max-height: 65px !important;
            &.show{
                opacity: 1;
                display: contents;
                width: 100%;
                height: 65px !important;
            }
            tr{
                height: 65px !important;
                overflow: hidden;
                min-height: 65px !important;
                max-height: 65px !important;
            }
        }
        
    }    
}
.page-head{
    color: $gray-27;
    font-size: 28px;
}
.table-primary{
    background-color: $white;
    color: $black;
    thead{
     background-color: $table-head-bg;
     th{
        border: 0;
        font-weight: normal;
        vertical-align: middle;
    }   
    }
    tbody{
        td{
            border-top:0;
            border-bottom: 1px solid $border-color;
        }
    }
 }

 .mr10{
     margin-right: 10px;
 }
 .none{
     display: none;
 }
 .active-row{
     display: table-row;
 }
 .type-color{
    display: inline-block;
    color: #FFF;
    padding: 2px;
    font-size: 12px;
    border-radius: 2px;
    background-color: rgba(22,128,94,0.5);
    width: 45px;
    text-align: center;
    &.back {
        background-color: $back-bg;
    }
    &.lay {
        background-color: $lay-bg;
    }
 }
 .history-results{
     display: inline-block;
     margin-right: 10px;
     color: $white;
     padding:2px 5px;
     font-size: 12px;
     border-radius: 2px;
     min-width: 54px;
     text-align: center;
     &.pending{
         background-color: orange;
     }
     &.won{
        background-color: var(--main-color);
     }
     &.loss{
        background-color: $red;
     }
     &.cancel{
        background-color:  $gray-10 ;
     }

 }
 .green-color{
     color: $primary;
 }
 .total-block{
    // text-align: right;
    margin-top: 10px;
     div{
         display: inline-block;
         &:first-child{
             margin-right: 10px;
         }
     }
     h4{
        font-size: 20px;
        margin: 5px 0 5px 0;
        color:$gray-44;
        font-weight: $font-weight-medium;
     }
     p{
        font-size: 20px;
        margin: 0;
        font-weight: $font-weight-medium;
        line-height: 24px;
        text-align: center;
     } 
     @media (max-width:767px) {
         text-align: center;
         margin-top: 15px;
     }
 }
 @media(max-width:767px){
     .bethistory-responise-tbl{
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
        
        thead  { 
            tr{
                position: absolute;
                top: -9999px;
                left: -9999px;

            }
        }
        tr{
            border-bottom: 15px solid $body-bg;
            &.active-row{
                td:nth-of-type(1) { display: none;}
                td:nth-of-type(3) { display: none;}
                td:nth-of-type(4) { display: none;}
                td:nth-of-type(6) { display: none;}
                td:nth-of-type(7) { display: none;}
                td:nth-of-type(8) { display: none;}
                td:nth-of-type(9) { display: none;}
              //  td:nth-of-type(10) { display: none;}
                td:nth-of-type(11) { display: none;}
                
                td:nth-of-type(2) { 
                    padding:10px 10px 10px 10px;
                }

            }
            &.none{
                display: none;
            }
        }
        td { 
            /* Behave  like a "row" */
            min-height: 38px;
            border: none;
            border-bottom: 1px solid #eee; 
            position: relative;
            padding: 7px 0 7px 50%;
            &:before { 
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                width: 45%; 
                padding-right: 10px; 
                white-space: nowrap;
                color: $black;
            }
            text-align: left !important;
        }
        	/*
	Label the data
	*/td:nth-of-type(1){
        padding: 10px 10px 0 10px;
        border-bottom: 0;
        min-height: auto;
    }
    td:nth-of-type(2){
        padding:0 10px 10px 10px;
    }
    td:nth-of-type(1):before { content: ""; display: none; }
	td:nth-of-type(2):before { content: "";  display: none;}
	td:nth-of-type(3):before { content: "Bet Type"; }
	td:nth-of-type(4):before { content: "Type"; }
	td:nth-of-type(5):before { content: "Odds"; }
	td:nth-of-type(6):before { content: "Stack"; }
	td:nth-of-type(7):before { content: "Liability"; }
	td:nth-of-type(8):before { content: "Potential Profit"; }
	td:nth-of-type(9):before { content: "Ip Address"; }
	td:nth-of-type(10):before { content: "Result"; }
	td:nth-of-type(11):before { content: "Action"; }
    &.past{

        td:nth-of-type(1):before { content: ""; display: none; }
        td:nth-of-type(2):before { content: "";  display: none;}
        td:nth-of-type(3):before { content: "Bet Type"; }
        td:nth-of-type(4):before { content: "Type"; }
        td:nth-of-type(5):before { content: "Odds"; }
        td:nth-of-type(6):before { content: "Stack"; }
        td:nth-of-type(7):before { content: "Profit/Loss"; }
        td:nth-of-type(8):before { content: "Ip Address"; }
        td:nth-of-type(9):before { content: "Result"; }
        td:nth-of-type(10):before { content: "Action"; }
        .active-row{
            
                td:nth-of-type(9) { display: block;}
                td:nth-of-type(10) { display: none;} 
        }
    }
     }

     .statement-responise-tbl{
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
        
        thead  { 
            tr{
                position: absolute;
                top: -9999px;
                left: -9999px;

            }
        }
        tr{
            border-bottom: 15px solid $body-bg;
           
        }
        td { 
            /* Behave  like a "row" */
            min-height: 38px;
            border: none;
            border-bottom: 1px solid #eee; 
            position: relative;
            padding: 7px 0 7px 50%;
            &:before { 
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                width: 45%; 
                padding-right: 10px; 
                white-space: nowrap;
            }
            text-align: left !important;
        }
        	/*
	Label the data
	*/td:nth-of-type(1){
       // padding: 10px 10px 0 10px;
      //  border-bottom: 0;
      //  min-height: auto;
    }
    td:nth-of-type(2){
    //    padding:0 10px 10px 10px;
    }
	td:nth-of-type(1):before { content: "S.No";}
	td:nth-of-type(2):before { content: "Date"; }
	td:nth-of-type(3):before { content: "Type"; }
	td:nth-of-type(4):before { content: "Description"; }
	td:nth-of-type(5):before { content: "Lena / Dena"; }
	td:nth-of-type(6):before { content: "Balance"; }
	 }
 }
 .block{
    display: block ;
}
.delete-unmatch-row{
    &.sm{
        border:none;
        color: $black !important;
    }
    font-size: 20px;
    color: $gray-10 !important;
    border: 1px solid $border-color;
    padding: 2px;
    border-radius: 2px;
    position: relative;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    i{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}