.white-box{
    border:1px solid $border-color;
    background-color: $white;
    padding:32px 20px;
    border-radius: 6px;
    margin:30px 0;
    .title{
        font-size: 18px;
        line-height: 21px;
        font-weight: $font-weight-bold;
    }
    .sub-title{
        font-size: 16px;
        font-weight: $font-weight-medium;
        margin-top: 15px;
        margin-bottom: 40px;
    }
}
.payment-option-row{
    padding: 0;
    margin: 20px 0 40px 0;
    li{
        display: inline-block;
        width: calc(100% /  4 - 6px);
        border:2px solid $border-color;
        border-radius: 6px;
        margin: 0 8px 0 0;
        height: 102px;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        &:last-child{
            margin-right: 0px;
        }
        &.acitve{
            background-color: var(--main-color);
            border-color: var(--main-color);
        }
        img{
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    @media (max-width:767px) {
        li{
            width: calc(100% / 2 - 8px);
            margin: 0 8px 10px 0;
            img{
                max-width: 75%;
            }
        }
    }
}
.deposit-form{
    .btn{
        min-width: 178px;
        margin-top: 45px;
    }
    .btn-mob{
        display: none;
    }
    .btn-xlg{
        min-width: 260px;
    }
    @media(max-width:767px){
        .btn-web{
            display: none;
        }
        .btn-mob{
            display: inline-block;
        }
    }
}
.upload-doc{
    border:2px dashed $border-color;
    min-height: 256px;
    padding: 5px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    .upload-image{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-decoration: underline !important;
        color: $gray-999;
        font-size: 16px ;
    }
}

.list-widthdraw-amount{
    padding: 0;
    margin: 0 0 0 0;

    li{
        display: inline-block;
        background-color: $gray-999;
        border-radius: 25px;
        font-size: 16px;
        font-weight: $font-weight-bold;
        padding: 6px 26px;
        color:$white;
        margin: 0 10px 10px 0;
        cursor: pointer;
        &:last-child{
            margin-right: 0;
        }
    }
}