
@mixin oddswidth() {
    // max-width: $max-width-odds;
    // margin-left: auto;
    // margin-right: auto;
}
@mixin container-margin-none() {
    margin-left: -$grid-gutter-width * .5;
    margin-right: -$grid-gutter-width * .5;
}

@mixin flexwrap() {
    display: flex;
    flex-wrap: wrap;
    > * {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}
@mixin SelectedOdd() {
    // background-color: $yellow;
    // color: $gray-27 !important;
    // box-shadow: inset 0 0 4px 2px rgba($black, .4);
    box-shadow: inset 0 0 8px 0.2px rgba($black, .4);
}
