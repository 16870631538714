.led_tab {
  table {
    border: 4px solid #fff !important;
    text-align: center;
    thead {
      background-color: var(--main-color);
      color: #fff !important;
      border: 4px solid;
      th {
        border: 4px solid;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    tbody {
      background-color: #280d53;
      color: #fff !important;
      border: 4px solid;
      td {
        border: 4px solid;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .spt_nm {
        &:hover {
          text-decoration: underline !important;
          cursor: pointer;
        }
      }
    }
  }
}

// ledger details table
.led_del_tab {
  table {
    border: 4px solid #fff !important;
    text-align: center;
    thead {
      background-color: var(--main-color);
      color: #fff !important;
      border: 4px solid;
      th {
        border: 4px solid;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    tbody {
      background-color: #280d53;
      color: #fff !important;
      border: 4px solid;
      td {
        border: 4px solid;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.box {
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #ffffff;
  border: 4px solid #c3c8c8;
  width: 100%;
  color: red;
  span.loss {
    color: red;
  }
  span.won {
    color: green;
  }
}
.box1 {
  background-color: var(--main-color);
  border: 4px solid #c3c8c8;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.box2 {
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #ffffff;
  border: 4px solid #c3c8c8;
  color: blue;
  width: 100%;
}
.b1_btl {
  background-color: var(--main-color) !important;
  border: 4px solid #c3c8c8;
  color: #ffffff !important;
  width: 100%;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  a:hover {
    text-decoration: underline !important;
    color: #fff !important;
  }
}
.mobile-ledger-page-title{
  text-align: center;
  margin: 10px 0px;
}
.mobile-ledger-page-card{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  .card-body{
    padding: 50px 0px 0px 0px;
  }
}
table.mobile-ledger-table{
  thead{
    background-color: #f0f0f0;
    color: #a4a4a4;
    font-size: 12px;
    font-weight: 600;
    tr{
      th {
        border: 0px solid transparent;
        padding: 10px 5px;
        text-align: center;
      }
    }
  }
  tbody{
    font-size: 12px;
    font-weight: 600;
    color: #575757;
    tr{
      td{
        padding: 10px 5px;
        text-align: center;
        &:first-child{
          width: 65px;
        }
      }
    }
  }
}