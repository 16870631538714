:root {
  --main-color {
    color: $primary;
  }

  --header-button-bgcolor {
    color: #696969;
  }
}

@keyframes background-position {
  0% {
    background-position-y: 100%;
  }

  100% {
    background-position-y: 0;
  }
}

.app-toolbar {
  height: 65px;
  background-color: var(--main-color);
  animation: background-position 20s ease-in-out infinite alternate;
  // background: $primary; //url(../img/73.png) 0 100% no-repeat;
  background-size: cover;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.52, 0.16, 0.24, 1);

  @include media-breakpoint-down(sm) {
    height: 75px;
  }

  .mobile_admin_msg {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      color: white;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  .mobile-hidden {
    color: white;

    .icon-menu {
      cursor: pointer;
      float: left;
      font-size: 24px;
      // margin: 17px 0px 0px 10px;
      margin: 8px 8px 0px 10px;

      @include media-breakpoint-down(sm) {
        // margin: 25px 0px 0px 10px;
      }
    }

    .site_logo {
      float: left;
      width: 185px;
      margin: 15px 15px 4px;
      font-family: NotoSans;
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;

      @include media-breakpoint-down(sm) {
        width: 140px;
        font-size: 22px;
        margin: 25px 5px 4px;
      }
    }

    .admin_msg {
      float: left;
      width: calc(100% - 470px);
      margin-top: 23px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .user-profile {
      width: 220px;
      float: right;
      margin-top: 10px;
      margin-right: 0px;

      @include media-breakpoint-down(sm) {
        width: 175px;
        font-size: 10px;
      }

      .profile-image {
        width: 45px;
        float: left;

        img {
          width: 100%;
        }
      }

      .user_profile_detail {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;

        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }

        .icon-logout {
          font-size: 24px;
          margin-top: 10px;
          margin-right: 5px;
        }

        span.header-user-nm {
          display: block;
          margin: 0;
          padding: 0;

          @include media-breakpoint-down(sm) {
            font-size: 10px;
          }
        }

        span.header-user-coins {
          display: block;
          margin: 4px 4px 2px 0;

          @include media-breakpoint-down(sm) {
            margin: 6px 4px 2px 0;
            font-size: 10px;
          }
        }
      }
    }
  }
}

.custom-drawer {
  .drawer-body {
    a.nav-item {
      &.active {
        background-color: #fff;
        color: var(--main-color) !important;
      }
    }
  }
}

.drawer-header {
  @include clearfix;
  padding: 30px 0 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 5px;
  background-color: var(--main-color);
  // box-shadow: 0 0 5px 4px rgba(black, .3);
  height: 150px;

  &.drawer-header-inner {
    background-color: var(--main-color);
    min-height: 80px;
    height: auto;
  }

  .logo {
    display: block;
    margin: 20px auto;
    width: 144px;
    height: 39px;

    img {
      width: 100%;
    }
  }

  .icon-cancel {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 12px;
    padding: 9px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    color: $white;
    cursor: pointer;
  }
}

.drawer-header-body {
  background-color: $primary !important;
  color: white !important;

  .logo {
    margin: -14px 0 0 20px;
    // img{
    //     width: 64%;
    // }
  }

  .user-profile-data {
    // text-align: center;
    padding: 15px 20px 3px;

    .figure {
      width: 80px;
      height: 80px;
      margin: auto;
      overflow: hidden;
      border-radius: 100%;
      margin-bottom: 10px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    h4 {
      font-size: 20px;
      line-height: 27px;
      margin: 0;
      color: $white;
      text-transform: capitalize;
    }
  }

  .user-budget {
    font-size: 16px;
    //    text-align: center;
    //    margin-top: 10px;
    padding: 0 20px 15px;

    .balance {
      color: $body-bg;
    }

    .exposure {
      margin-left: 10px;
      background-color: #267531;
      color: $white;
      padding: 4px 10px;
    }
  }
}

.dropdown-wrap {
  @include flexwrap;
  padding: 30px 20px;
}

.drawer-body {
  background-color: var(--main-color);
  color: white !important;
  @include clearfix;
  height: calc(100vh - 80px);
  margin-top: 80px;
  overflow-x: hidden;

  &.lg {
    height: calc(100vh - 80px);
    margin-top: 80px;
  }

  .nav-item {
    display: block;
    font-weight: 600;
    font-size: 0.9em !important;
    padding: 1em 2.5em 1em 1.2em;
    letter-spacing: 2px;
    color: white !important;
    text-transform: uppercase;
  }
}

.dropdown-menu-nav {
  border-top: solid 1px $border-color;
  margin-top: $dropdown-item-padding-y;

  .icn {
    display: block;
    margin: auto;
    width: 30px;
    height: 30px;
    font-size: 22px;
    padding: 4px 0;
    text-align: center;
    cursor: pointer;
  }
}

.sub-header {
  height: 35px;
  background-color: var(--main-color);
  animation: background-position 20s ease-in-out infinite alternate;
  color: #fff;
  // background: $primary; //url(../img/73.png) 0 100% no-repeat;
  background-size: cover;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.52, 0.16, 0.24, 1);

  @include media-breakpoint-down(sm) {
    height: 35px;
  }

  .sub-header-row {
    display: flex;
    flex-wrap: wrap;
    min-height: 35px;
    justify-content: center;

    .sub-header-cell {
      text-align: center;
      padding: 5px;
      cursor: pointer;
      min-height: 35px;

      @include media-breakpoint-down(sm) {
        font-size: 9px;
        padding: 5px 1px;
        font-weight: 900;
        width: 25%;

      }

      img {
        @include media-breakpoint-down(sm) {
          margin-bottom: 5px;
          width: 18%;
        }
      }

      &:first-child {
        @include media-breakpoint-down(sm) {
          margin-left: 0px;
        }
      }

      &.active {
        background-color: #ffffff;
        border-bottom: 2px solid #ffffff;
        color: var(--main-color);
      }

      .inplay,
      .upcoming {
        @include media-breakpoint-down(sm) {
          padding-top: 8px;
          display: block;
        }
      }

      &.mobile-view-header-cell {
        img {
          float: inherit;
          margin-top: 0px;
          margin-right: 10px;
        }
      }

      .sub-header-item {
        display: inline-block;
        margin-left: 5px;

        @include media-breakpoint-down(sm) {
          font-size: 10px;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-color);
  opacity: 0.5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-color);
}

.marquee {
  &:first-child {
    padding-right: 50px;
  }
}

// cup winners
.fs_i {
  font-size: initial;

  @include media-breakpoint-down(sm) {
    font-size: large;
  }

  &.active {
    color: #00bb00;
  }
}


.custom-logo-upload-modal {
  width: 40%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  left: 50%;

  .modal-body {
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
  }

  @include media-breakpoint-down(sm) {
    width: 80%;
  }

  .modal-title {
    font-weight: bold;
  }

  .e-tile {
    border: 0.5px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    width: 90%;
    margin: 0px 5px;
    border-radius: 4px;

    @include media-breakpoint-down(sm) {
      margin: 0px 4px;
    }



  }

  .selected-color {
    border: 1px solid red;
    transform: scale(1.1);
  }


}

.bg-main {
  background-color: var(--main-color);
}

.bg-secondary-color {
  background-color: var(--secondary-color);
}


.btn-primary {
  color: #ffffff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-primary:hover {
  color: #ffffff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}


.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}


.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.bg-primary {
  background-color: var(--main-color) !important;
}

.bg-gradient-1 {
  background: linear-gradient(45deg, var(--main-color), var(--secondary-color)) !important;
}

.bg-gradient-2 {
  background: linear-gradient(45deg, var(--secondary-color), var(--main-color)) !important;
}

.bg-gradient-3 {
  background: linear-gradient(180deg, var(--main-color), var(--secondary-color)) !important;
}

.bg-gradient-4 {
  background: linear-gradient(180deg, var(--secondary-color), var(--main-color)) !important;
}

.bg-radial-1 {
  background: radial-gradient(var(--main-color), var(--secondary-color));
}

.bg-radial-2 {
  background: radial-gradient(var(--secondary-color), var(--main-color));
}

.bg-gradient-white {
  background: linear-gradient(180deg, #fff, #e9e9e9cc) !important;
}

.bg-gradient-odd {
  background: linear-gradient(180deg, #61acde, #4a83a9) !important;
}

.bg-gradient-lay {
  background: linear-gradient(180deg, #f6b771, #b38654) !important;
}



.text-primary {
  color: var(--main-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}

@keyframes pulse {
  50% {
    opacity: .7
  }
}