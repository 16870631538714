* {
    outline: none !important;
    -webkit-appearance: none !important;
}

a {
    &:hover {
        text-decoration: none;
    }
}

html {
    -webkit-text-size-adjust: none
}

html,
body {
    font-size: 14px;
    font-family: $font-family-sans-serif;

    @include media-breakpoint-down(sm) {
        font-family: $mobile-font-family-sans-serif;
    }
}

body {
    #root {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
    }
}

.overflow {
    overflow: hidden;
}

.btn {
    // letter-spacing: 1px;
    font-size: 18px;
    font-weight: $font-weight-normal;
    outline: none;
    box-shadow: none;

    &:not(:disabled):not(.disabled) {

        &:focus,
        &.focus {
            box-shadow: none;
        }
    }

    &:disabled {
        cursor: default;
        background: $disabled-color-100;
        color: $gray-999;
        background: #999999;
        color: rgba($white, 1);
        text-shadow: none !important;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.21);
    }

    &.round {
        border-radius: 24px;
    }
}

.btn-sm {
    font-size: 14px;
}

.btn-primary {
    // background: linear-gradient(350deg, #333399 28%, #FF00CB 100%);
    // text-shadow: 0 3px 3px rgba(0, 0, 0, 0.62) !important;
    // box-shadow: 0 8px 13px 0 rgba(0,0,0,0.21);

}

.btn-link {
    box-shadow: none !important;
    text-decoration: none !important;

    &:disabled {
        background: transparent;
    }
}

.form-control:focus {
    border-color: #979797;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #F7F5F9;
}

.pwd-form-group {
    position: relative;

    .pwd-eye {
        width: 44px;
        height: 44px;
        position: absolute;
        font-size: 20px;
        right: 0;
        top: 0;
        color: $gray-999;
        padding: 12px;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }
}

.text-normal {
    font-weight: $font-weight-normal;
}

.text-bold {
    font-weight: $font-weight-bold;
}

.text-medium {
    font-weight: $font-weight-medium;
}

.text-black {
    font-weight: $font-weight-black;
}

.gutters-5px {
    margin-right: -5px;
    margin-left: -5px;

    >.col,
    >[class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.gutters-10px {
    margin-right: -10px;
    margin-left: -10px;

    >.col,
    >[class*="col-"] {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.gutters-7px {
    margin-right: -7px;
    margin-left: -7px;

    >.col,
    >[class*="col-"] {
        padding-right: 7px;
        padding-left: 7px;
    }
}

.gutters-20px {
    margin-right: -20px;
    margin-left: -20px;

    >.col,
    >[class*="col-"] {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.gutters-container-20px {
    padding-left: 20px;
    padding-right: 20px;

    >.row {
        @extend .gutters-20px;
    }
}

.gutters-container-10px {
    padding-left: 10px;
    padding-right: 10px;

    >.row {
        @extend .gutters-10px;
    }
}

.gutters-container-7px {
    >.row {
        @extend .gutters-7px;
    }
}

a {
    cursor: pointer;

    &:not([href]) {
        color: $primary;

        &:hover {
            color: darken($primary, 20);
        }
    }
}



@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }

    .btn-block+.btn-xs-block,
    .btn-xs-block+.btn-block,
    .btn-xs-block+.btn-xs-block {
        margin-top: 0.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }

    .btn-block+.btn-sm-block,
    .btn-sm-block+.btn-block,
    .btn-sm-block+.btn-sm-block {
        margin-top: 0.5rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }

    .btn-block+.btn-md-block,
    .btn-md-block+.btn-block,
    .btn-md-block+.btn-md-block {
        margin-top: 0.5rem;
    }
}

@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }

    .btn-block+.btn-lg-block,
    .btn-lg-block+.btn-block,
    .btn-lg-block+.btn-lg-block {
        margin-top: 0.5rem;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

ul,
ol {
    list-style: none;
    padding: 0;
}



.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;

    & .path {
        stroke: rgba($blue, .7);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    &.light {
        .path {
            stroke: rgba($primary, .7);
        }
    }

}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}


@include media-breakpoint-down(sm) {
    .no-padding-sm {
        padding-left: 0;
        padding-right: 0;
    }
}

.page-loading {
    text-align: center;
    position: absolute;
    width: 90px;
    height: 74px;
    top: 50%;
    left: 50%;
    margin: -37px 0 0 -45px;
    padding: 10px 0;
    background-color: $white;
    border-radius: 6px;
    box-shadow: $box-shadow-sm;

    &.relative {
        position: static;
        margin: 0 auto;
        user-select: none;
    }

    .spinner {
        position: static;
        margin: 0;
    }

    .page-loading-title {
        margin-top: 3px;
    }
}

.p-0 {
    padding: 0 !important;
}


.toast-notification {
    @include media-breakpoint-down(sm) {
        width: 100% !important;

        span {
            width: 100% !important;
            border-radius: 0 !important;
            padding: 10px 20px !important;
        }
    }
}

.form-group {
    margin-bottom: 15px;
}

.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.add-to-home-banner {
    padding: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.21);
    z-index: 10000;

    .add-to-home-icon {
        padding: 0 !important;
    }
}

// transform: scaleX(-1);

.site-content {
    flex: 1;
    min-height: 500px;
}

.overflow-hidden {
    overflow: hidden !important;
}

.lock-screen {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

.text-truncate {
    @include text-truncate;
}

// .green {
//     background-color: $green !important;
// }
// .red {
//     background-color: $red !important;
// }
// .yellow {
//     background-color: $yellow !important;
// }
// .pink {
//     background-color: $pink !important;
// }
.spinner-posting {
    max-width: 25px;
    max-height: 25px;
    display: block;
    margin: auto;
}

.no-margin-h {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.no-margin-v {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.no-margin-l {
    margin-left: 0 !important;
}

.no-margin-r {
    margin-right: 0 !important;
}

.no-margin-b {
    margin-bottom: 0 !important;
}

.no-margin-t {
    margin-top: 0 !important;
}

.infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
        overflow: initial !important;
    }
}

.message-box {
    text-align: center;
    padding: 20px;
    // background-color: $white;
    border-radius: 6px;
    // box-shadow: 0 3px 5px 1px rgba($black, .1);
    max-width: 300px;
    margin: 0 auto 20px;
    color: rgba($black, .3);

    .heading {
        font-size: 24px;
        font-weight: $font-weight-bold;

        &.coming_soon {
            @keyframes blinker {
                50% {
                    opacity: 0;
                }
            }

            color: var(--main-color);
            animation: blinker 1s linear infinite;
        }
    }

    .paragraph {
        font-size-adjust: 14px;
    }

    &.sm {
        max-width: 300px;
    }

    &.md {
        max-width: 500px;
    }

    &.lg {
        max-width: 700px;
    }
}

.danger-color {
    color: $red !important;
}

.dropdown-item {
    .block {
        display: block;
        color: $body-color;
    }
}

.max-width-container {
    max-width: 1400px;
    @include clearfix;
    margin: auto;
}


// POC 
.poc-wrapper {
    @include clearfix;
    max-width: 600px;
    margin: 20px auto;

    .table {
        margin: 0;

        * {
            vertical-align: middle;
        }
    }

    .card {
        margin-top: 20px;

        .card-title {
            padding: 0.75rem;
            margin: 0;
        }
    }

    .back-bg-0 {
        background-color: $back-ultra-light-bg;
    }

    .back-bg-1 {
        background-color: $back-light-bg;
    }

    .back-bg-2 {
        background-color: $back-bg;
    }

    // .back-bg {
    //     background-color: $back-bg;
    // }
    .lay-bg-0 {
        background-color: $lay-bg;
    }

    .lay-bg-1 {
        background-color: $lay-light-bg;
    }

    .lay-bg-2 {
        background-color: $lay-ultra-light-bg;
    }
}