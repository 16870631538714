.casino_wall {
  background-image: url(../../Assets/img/casino_logo.jpg);
  background-size: 100% 100%;
  border: 6px solid #fff;
  color: transparent;
  padding: 10rem;
}
.comming_soon {
  text-align: center;

  h1 {
    color: var(--main-color);
    font-size: 7rem;
    @include media-breakpoint-down(sm) {
      font-size: 3rem !important;
    }
  }
}
.gams_img {
  img {
    width: 100%;
    height: 40%;
    border: 4px solid;
    transition: 0.5s all ease-in-out;
  }
}
.gms_name {
  background-color: var(--main-color) !important;
  color: #fff !important;
  padding: 0px !important;
  text-align: center !important;
}
.gams_img:hover img {
  transform: scale(1.2);
  cursor: pointer;
}

.crd_gms {
  @include media-breakpoint-down(md) {
    margin-top: 8px !important;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 8px !important;
  }
}
