.onboarding-wrap{
    background-color: $white;
    border:1px solid $border-color;
    border-radius: 8px;
    padding: 30px 90px;
    max-width: 860px;
    margin: 20px auto;
    .title{
        font-size: 26px;
        line-height: 32px;
        font-weight: $font-weight-bold;
    }
    Form{
        margin-top: 45px;
    }
    .btn{
        width: 160px;
        margin-top: 60px;
    }
    @media (max-width:767px){
        width: 100%;
        padding: 30px 20px;
    }
}