.betslip-wrap {
  width: 100%;


  @include clearfix;
  // margin-top: 20px;


  @include media-breakpoint-down(sm) {
    margin-top: 5px;
  }

  .message-box {
    margin: 0 auto;
  }

  .alert-danger,
  .alert-warning {
    margin: 8px 0 0;
    font-size: 12px;
    line-height: 1.2;
    padding: 7px 10px;
  }

  &.only-single-bet {
    .betslip-container {
      // border-bottom: none;
      border-radius: 4px;
    }

    .betslip-footer {
      margin: 0;
      padding-top: 0;
      border: none;
      border-radius: 0 0 4px 4px;

      .btn {
        margin: 0 auto;
        background-color: var(--main-color);
        color: #fff;

      }
    }

    // &.blank {
    //     .betslip-container {
    //         border-bottom: solid 1px $border-color;
    //         border-radius: 4px;
    //     }
    // }
  }

  &+.betslip-wrap {
    margin-top: 8px;
  }

  &.betslip-open-bet {
    .pagination {
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      position: relative;

      // height: calc(100vh);
      // background-color: $white;
      .betslip-container {
        // height: calc(62vh);
        border-radius: 0;
      }

      .mobile-fancy {
        // height: calc(78vh);
      }

      .pagination-sm {
        border-top: solid 1px rgba($gray-27, 0.2);
        padding: 10px 0 6px;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .pagination {
          margin-top: 0%;
        }
      }
    }
  }
}

.betslip-container {
  // min-height: 255px;
  border: solid 1px $border-color;
  border-radius: 4px;
  background-color: $white;

  &+.betslip-container {
    margin-top: 8px;
  }

  .betslip_runner_name {
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
      padding: 0px 0px 10px 0px;
    }
  }

  .lagai {
    border-top: 2px solid $back-odd !important;
  }

  .khai {
    border-top: 2px solid $lay-odd !important;
  }

  .live_tv {
    iframe {
      border: 0px solid transparent;
      min-height: 280px;
    }
  }

  .collapse {
    background-color: $white;
  }

  button {
    &.betslip_close {
      background-color: red !important;
      color: #ffffff !important;

      &:active,
      &:hover {
        background-color: red !important;
        color: #ffffff !important;
      }
    }

    &.betslip_submit {
      background-color: green !important;
      color: #ffffff !important;

      &:active,
      &:hover {
        background-color: green !important;
        color: #ffffff !important;
      }
    }
  }
}

.betslip-header {
  font-weight: $font-weight-medium;
  color: $white;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.2;
  padding: 12px 20px;
  background-color: var(--main-color);
  position: relative;
  cursor: pointer;

  a.closeBetSlipWindow {
    float: right;
    color: #ffffff;
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .icon-down,
  .icon-up {
    color: $white;
    position: absolute;
    right: 15px;
    top: 13px;
  }
}

.betslip-container-mobile {
  // min-height: 255px;
  border: solid 1px $border-color;
  border-top-left-radius: 33px;
  border-top-right-radius: 33px;
  background-color: $white;

  &+.betslip-container-mobile {
    margin-top: 8px;
  }

  span.clock-timer {
    color: var(--main-color);
  }

  a.closeBetSlipWindow {
    margin-top: -21px;
    position: fixed;
    font-size: 20px;
    right: 55px;
    font-weight: 900;
    color: #ffffff;
    width: 40px;
    height: 40px;
    padding: 5px 13px;
    border-radius: 36px;
    background-color: var(--main-color);
    z-index: 9999999;
  }

  .betslip_runner_name {
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
      padding: 0px 0px 10px 0px;
    }
  }

  .lagai {
    background-color: $back-odd !important;
    color: #000;
  }

  .khai {
    background-color: $lay-odd !important;
    color: #000;
  }

  .live_tv {
    iframe {
      border: 0px solid transparent;
      min-height: 280px;
    }
  }

  .collapse {
    background-color: $white;
  }

  button {
    &.betslip_close {
      background-color: red !important;
      color: #ffffff !important;

      &:active,
      &:hover {
        background-color: red !important;
        color: #ffffff !important;
      }
    }

    &.betslip_submit {
      background-color: green !important;
      color: #ffffff !important;

      &:active,
      &:hover {
        background-color: green !important;
        color: #ffffff !important;
      }
    }
  }

  .betslip-footer {
    .btn {
      width: 100%;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 900;
    }
  }
}

.betslip-header-mobile {
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 1.2;
  padding: 25px 20px 5px;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  cursor: pointer;

  b.bet-slip {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 5px;
  }

  b.match-name {
    margin-bottom: 5px;
  }

  b.market-name {
    text-transform: uppercase;
    color: var(--main-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .icon-down,
  .icon-up {
    color: $white;
    position: absolute;
    right: 15px;
    top: 13px;
  }
}

.betslip-tabs {
  @include flexwrap;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  // background-color: $gray-999;
  background-color: var(--secondary-color);
  color: $white;
  // padding: 5px 10px;

  @include media-breakpoint-down(sm) {
    // padding: 5px 10px;
    font-size: 9px;
  }

  >span {
    // padding: 5px 0 4px;
    cursor: pointer;
    padding: 7px 7px;

    @include media-breakpoint-down(sm) {
      padding: 5px 7px;
    }

    &.active {
      cursor: default;
      background-color: var(--main-color) !important;
    }
  }

  &+.betslip-common-error {
    margin-top: 8px !important;

    &.alert-danger {
      background-color: red;
      color: white;
      border-color: red;
    }
  }
}

.betslip-filter-pills {
  @include clearfix;
  padding: 13px 9px;

  >span {
    display: inline-block;
    font-size: 13px;
    line-height: 1.3;
    text-align: center;
    background-color: $gray-999;
    border: solid 1px $gray-999;
    color: $white;
    cursor: pointer;
    padding: 4px 13px;
    border-radius: 16px;
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }

    &.warning {
      color: #850000 !important;
      background-color: #ffcccc !important;
      border-color: #ffb8b8 !important;

      // color: #856404 !important;
      // background-color: #fff3cd !important;
      // border-color: #ffeeba !important;
    }

    &.active {
      cursor: default;
      border-color: $green;
      background-color: $green;
    }
  }
}

.betslip-footer {
  margin-top: 8px;
  margin-bottom: 8px;
  // height: 132px;
  border: solid 1px $border-color;
  border-radius: 4px;
  background-color: $white;
  padding: 11px 14px;
  font-size: 12px;
  line-height: 1.5;
  color: rgba($gray-27, 0.5);
  position: relative;

  .icon-trash {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 27px;
    height: 27px;
    color: $gray-999;
    border: solid 1px rgba($gray-999, 0.5);
    border-radius: 4px;
    font-size: 23px;
    padding: 0px 1px;
    cursor: pointer;
  }

  b {
    font-weight: $font-weight-normal;
    color: $gray-27;
    font-size: 14px;
  }

  .btn {
    // width: auto;
    width: 100%;
    font-size: 15px;
    // margin: 24px auto 0;
    margin: 14px auto 0;

    @include media-breakpoint-down(sm) {
      font-size: 10px;
      padding: 0.5rem 1rem;
    }
  }
}

.betslip-list {
  @include clearfix;
  padding: 0 5px 10px;
  position: relative;

  .message-box {
    margin: auto;
  }

  &.m-t {
    margin-top: 10px;
  }

  &.mulitple-bet {
    padding-bottom: 85px;
  }
}

.betslip-item {
  border: solid 1px #9a93e0;
  border-radius: 6px;
  background-color: $white;
  // height: 172px;
  margin-top: 10px;

  &.lay-slip {
    border-color: $lay-bg;
  }

  &:first-child {
    margin-top: 0;
  }
}

.betslip--header {
  background-color: $back-bg;
  color: #942b52;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: $font-weight-book;
  height: 30px;
  border-radius: 5px 5px 0 0;

  &.lay-slip {
    background-color: $lay-bg;
  }

  .text-truncate {
    padding: 2px 11px;
  }

  .icon-close-circle {
    float: right;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    padding: 4px;
    cursor: pointer;
  }
}

.betslip--body,
.betslip-list-footer {
  padding: 10px 10px 0;

  >.row {
    margin-bottom: 11px;
  }

  .title {
    font-size: 16px;
    @include text-truncate;
  }

  .small {
    font-weight: $font-weight-book;
    font-size: 14px;
    line-height: 1.4;
    color: $primary;
  }

  .stake-input {
    height: 30px;
    width: 130px;
    border-radius: 4px;
    background-color: #e8eaef;
    font-size: 14px;
    line-height: 1.4;
    border: none;
    padding: 8px 11px 7px 11px;
    margin-right: 8px;

    @include media-breakpoint-down(sm) {
      width: 110px;
    }
  }

  .return--small {
    font-weight: $font-weight-book;
    font-size: 14px;
    line-height: 1.2;
    color: #9398a0;
    // margin-top: 10px;
    // text-align: right;
  }

  .return-val {
    color: $black;
    line-height: 1;
    // text-align: right;
  }

  .return-value-col {
    display: inline-block;
    vertical-align: -5px;
  }
}

.betslip--footer {
  text-align: center;
  padding: 0 10px 10px;

  >a {
    text-align: center;
    flex: 1;
    display: inline-block;
    font-size: 14px;
    line-height: 1.22;
    // padding: 5px 10px;
    padding: 12px 20px;
    width: 132px;
    background-color: var(--main-color);
    color: $white !important;
    border-radius: 4px;
    margin-left: 6px;
    height: 41px;

    // @include media-breakpoint-down(sm) {
    //   max-width: 73px;
    //   width: 75px;
    // }
    @include media-breakpoint-down(sm) {
      font-size: 10px;
      // padding: 12px 0px;
      padding: 10px;
      // width: 70px;
      width: 22%;
      // height: 40px;
      height: 31px;
    }

    &.mobile-bet-button {
      font-size: 12px;
      padding: 5px;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      border-radius: 55px;
    }
  }
}

.betslip-list-footer {
  padding: 10px;
  background-color: $white;
  position: absolute;
  border-radius: 0 0 4px 4px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 85px;

  .betslip--footer {
    padding-bottom: 0;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.betslip-common-error {
  margin: 0 5px 8px !important;
}

.betslip-drawer-toggle {
  width: 100%;
  max-width: 130px;
  position: fixed;
  z-index: $zindex-sticky;
  bottom: 0;
  right: 30px;
  padding: 5px 10px 3px;
  background-color: $yellow;
  border-radius: 4px 4px 0 0;
  text-align: center;
  color: $gray-27;
  font-size: 14px;
  font-weight: $font-weight-medium;
  cursor: pointer;
  user-select: none;
  box-shadow: 1px 0 5px 1px rgba($gray-27, 0.3);

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    right: 0;
    border-radius: 0;
    padding: 8px 10px 7px;
  }
}

.betslip-drawer {
  background-color: rgba(0, 0, 0, 0.9);

  .betslip-wrap {
    margin: 0;
    overflow: hidden;

    &+.betslip-wrap {
      margin-top: 8px;
    }
  }

  .betslip-container {
    border: none;
  }

  .betslip-footer {
    margin-bottom: 0;
    border-left-color: $white;
    border-right-color: $white;
    border-bottom-color: $white;
    border-radius: 0;
    // border-width: 1px 0 0 0;
  }
}

.sticky-outer-wrapper {
  &.fixed {
    width: 100%;
    // position: absolute;
  }
}

.sticky-betslip-placeholder {
  width: 100%;
  background-color: transparent;

  &+div {
    pointer-events: none;

    .betslip-drawer {
      pointer-events: all;
    }
  }
}

.sticky-betslip-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1040;
  background-color: #000;
}

/* Add Animation */
@keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.sticky-betslip {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;

  @include media-breakpoint-down(sm) {
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  .betslip-wrap {
    margin: 0;
  }

  .app-footer {
    display: block;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    overflow: hidden;
  }
}

.app-footer-toggle {
  height: 40px;
  background-color: $green;
  text-align: center;
  font-size: 14px;
  color: rgba($white, 0.5);
  position: relative;
  cursor: pointer;
  user-select: none;
  z-index: 1;

  .logo {
    float: left;
    width: 86px;
    height: 24px;
    margin: 5px 15px;
  }

  .app-footer-inner {
    padding: 10px 0;
    height: 40px;
  }

  .icon-up,
  .icon-down {
    position: absolute;
    top: 13px;
    right: 13px;

    color: $white;
  }

  .icon-up {
    right: 50%;
    margin-right: -7px;
  }
}

.table-bets {
  font-size: 12px;

  thead {
    th {
      color: $gray-999;
      font-size: 12px;
      font-weight: $font-weight-medium;
      border: 0;
      padding: 7px 4px;

      @include media-breakpoint-down(sm) {
        font-size: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 7px 4px;
        font-size: 12px;

        @include media-breakpoint-down(sm) {
          font-size: 10px;
        }
      }

      &.back {
        background-color: $back-bg;
      }

      &.lay {
        background-color: $lay-bg;
      }
    }
  }
}

.pagination-sm {
  .pagination {
    @include media-breakpoint-down(sm) {
      margin-top: 0%;
      margin-bottom: 0%;
    }

    >li {
      min-width: 22px !important;
      padding: 1px 7px 1px 5px;
      height: auto;
      margin: 0 4px;

      >a {
        padding: 2px 5px;
      }
    }
  }
}

.set_mod {
  background-color: var(--main-color);
  font-size: 17px;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}

.root-react-component-countdown-timer {
  position: absolute;
  float: right;
  right: 31px;
  top: 8px;

  .displayedTime {
    .countBox {
      .countBoxItem {
        display: none;

        &:first-child {
          display: block;

          .label {
            float: right !important;
            margin-top: 3px;
          }

          .count {
            float: left !important;
            margin-right: 5px;
            background-color: var(--main-color) !important;
          }
        }
      }
    }
  }
}

table.declared_session {
  background-color: white;

  thead {
    tr {
      background-color: var(--main-color) !important;
      color: white;

      th {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;

        &.session_name {
          min-width: 190px;
        }

        &.sr_no {
          width: 35px;
        }

        &.runs {
          text-align: center;
          width: 50px;

          @include media-breakpoint-down(sm) {
            width: 80px;
          }
        }

        &.status {
          text-align: center;
          width: 50px;

          @include media-breakpoint-down(sm) {
            width: 80px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;

        &.session_name {
          min-width: 190px;
        }

        &.action {
          width: 12px;
          text-align: center;

          button {
            padding: 5px;
          }
        }

        &.sr_no {
          width: 35px;
        }

        &.runs {
          text-align: center;
          width: 50px;
        }

        &.status {
          text-align: center;
          width: 50px;

          .loss {
            color: red;
          }

          .profit {
            color: green;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      th {
        text-align: center;
        padding: 5px;

        &.status {
          text-align: center;
          width: 50px;

          .loss {
            color: red;
          }

          .profit {
            color: green;
          }
        }
      }
    }
  }
}

.live_tv_section {
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  iframe {
    border: 0px solid transparent;
    min-height: 280px;
  }

  .betslip-container {
    @include media-breakpoint-down(sm) {
      height: calc(50vh) !important;
      border-radius: 0;
    }

    &.hide {
      height: calc(7vh) !important;
    }
  }
}


.new_betslip_body {
  border-bottom: 1px solid silver;
  padding: 0px 8px 0 5px;

  .col {
    padding: 5px;


    .lagai {
      background-color: $back-odd !important;
      border-radius: 5px;
      padding: 2px;
      height: 41px;
      font-size: 17px;
      padding-top: 6px;

      @include media-breakpoint-down(sm) {
        height: 30px;
        font-size: 10px;
        padding-top: 5px;
      }
    }

    .khai {
      background-color: $lay-odd !important;
      border-radius: 5px;
      padding: 2px;
      height: 41px;
      font-size: 17px;
      padding-top: 6px;

      @include media-breakpoint-down(sm) {
        height: 30px;
        font-size: 10px;
        padding-top: 5px;
      }
    }

    .time span {
      border: 2px solid #8bc34a;
      margin-right: 10px;
      color: red;
      padding: 0 20px;
      padding-top: 6px;
      height: 41px;
      font-size: 18px;

      @include media-breakpoint-down(sm) {
        padding: 0;
        width: 100%;
        margin-right: 4px;
        height: 30px;
        padding-top: 5px;
        font-size: 10px;
      }

    }


    @include media-breakpoint-down(sm) {
      font-size: 10px;

    }

  }

  .amount {
    input {
      height: 41px;
      font-size: 15px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        height: 30px;
        font-size: 10px;

      }


    }

    .back-odds {
      border: 2px solid $back-odd;
      border-radius: 4px;

    }

    .lay-odds {
      border: 2px solid $lay-odd;
      border-radius: 4px;
    }

  }
}

.active-stack {
  background-color: green !important;
}

.live_tv {
  iframe {
    border: 0px solid transparent;
    min-height: 290px;
    width: 100%;
    height: 100%;
  }
}

// ...
.score_1 {
  iframe {
    border: 0px solid transparent;
    // min-height: 220px;
    min-height: 230px;
    width: 100%;
    height: 100%;
  }
}