.setting {
  .set-box {
    height: auto;
    width: 150px;
    background-color: #fff;
    text-align: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 320px;
      text-align: center;
      justify-content: flex-start;
      justify-items: none;
      display: none;
      margin-top: 10px;
    }

    a {
      color: #b7b5b5 !important;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      span {
        font-size: 65px;
        margin-top: 10px;

        @include media-breakpoint-down(sm) {
          float: left;
          margin-top: 5px;
        }
      }

      hr {
        width: 60px;

        @include media-breakpoint-down(sm) {
          border: none;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          height: 45px;
          float: left;
          margin-left: 15px;
          width: 1px;
        }
      }

      p {
        font-size: 18px;

        @include media-breakpoint-down(sm) {
          float: left;
          margin-left: 25px;
          margin-top: 25px;
        }
      }
    }
  }

  a:hover {
    color: var(--main-color) !important;
  }
}

// launguage
ul {
  margin-top: 10%;
  width: 475px;
  display: flex;

  @include media-breakpoint-down(sm) {
    padding: 0px;
    display: grid;
    margin-top: 32%;
    width: auto;
  }

  li {
    background-color: #fff;
    font-size: 19px;
    margin-right: 2.5rem;
    text-align: center;
    float: left;
    min-width: 27vh !important;
    padding: 6px 10px 3px 5px;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      margin-right: 0px;
    }

    color: red;
    cursor: pointer;

    &.active {
      background-color: var(--main-color);
      color: #fff;
    }
  }

  li:hover {
    background-color: var(--main-color);
    color: #fff;
  }
}

// other setting
.color_set {
  .card_set {
    margin-top: 10px;
  }

  .CboxRed {
    cursor: pointer;
    border: 4px solid $red !important;
    padding: 15px !important;
    background-color: $red;
  }

  .CboxPrimary {
    cursor: pointer;
    border: 4px solid #47388c !important;
    padding: 15px !important;
    background-color: #47388c;
  }

  .CboxBlue {
    cursor: pointer;
    border: 4px solid $blue !important;
    padding: 15px !important;
    background-color: $blue;
  }

  .CboxGreen {
    cursor: pointer;
    border: 4px solid $green !important;
    padding: 15px !important;
    background-color: $green;
  }

  .CboxOrange {
    cursor: pointer;
    border: 4px solid $orange !important;
    padding: 15px !important;
    background-color: $orange;
  }

  .CboxBlack {
    cursor: pointer;
    border: 4px solid $black !important;
    padding: 15px !important;
    background-color: $black;
  }

  .CboxYellow {
    cursor: pointer;
    border: 4px solid #dfff00 !important;
    padding: 15px !important;
    background-color: #dfff00;
  }

  .CboxCyan {
    cursor: pointer;
    border: 4px solid #2e4150 !important;
    padding: 15px !important;
    background-color: #2e4150;
  }

  .CboxYelloMd {
    cursor: pointer;
    border: 4px solid #ffbf00 !important;
    padding: 15px !important;
    background-color: #ffbf00;
  }

  .CboxPink {
    cursor: pointer;
    border: 4px solid #de3163 !important;
    padding: 15px !important;
    background-color: #de3163;
  }

  .CboxLightBlue {
    cursor: pointer;
    border: 4px solid #6495ed !important;
    padding: 15px !important;
    background-color: #6495ed;
  }

  .CboxLightGreen {
    cursor: pointer;
    border: 4px solid #40e0d0 !important;
    padding: 15px !important;
    background-color: #40e0d0;
  }

  .CboxMagenta {
    cursor: pointer;
    border: 4px solid #ff00ff !important;
    padding: 15px !important;
    background-color: #ff00ff;
  }
}

.color-btn {
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  padding: 12px 0;
  @include flexwrap;
  border: 4px solid #fff;
  margin: 0 4px;
  min-width: 20vh !important;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5px;
  }

  &:hover {
    transform: scale(1.2);
  }
}

.red-bg {
  background-color: $red !important;
}

.blue-bg {
  background-color: $blue !important;
}

.orange-bg {
  background-color: $orange !important;
}

.black-bg {
  background-color: $black !important;
}

.primary-bg {
  background-color: $primary !important;
}

.green-bg {
  background-color: $green !important;
}

.yellow-bg {
  background-color: #dfff00 !important;
}

.cyan-bg {
  background-color: #2e4150 !important;
}

.yellow-md-bg {
  background-color: #ffbf00 !important;
}

.pink-bg {
  background-color: #de3163 !important;
}

.light-blue-bg {
  background-color: #6495ed !important;
}

.light-geen-bg {
  background-color: #40e0d0 !important;
}

.magenta-bg {
  background-color: #ff00ff !important;
}

div.header_color_set {
  margin-left: 13px;

  div.header_card_set {
    height: 30px;
    float: left;
    width: 30px;
    border-radius: 31px;
    padding: 0px;
    margin-right: 1rem;
    margin-bottom: 20px;

    span.color-btn {
      min-width: 30px !important;
      height: 30px;
      width: 30px;
      border-radius: 30px;
      margin: 0px;
    }
  }
}

div.sports_name {
  width: 100%;
  margin-top: 5px;
  display: flex;

  hr {
    border-top: 1px solid #7d8d9d;
    float: left;
    width: 47%;
  }

  span {
    float: left;
    font-size: 14px;
    line-height: 1.3;
    background-color: var(--main-color) !important;
    color: #ffffff !important;
    padding: 5px 13px 5px 13px;
    border-radius: 9.5px;
    border: solid 0.7px #040506;
    min-width: fit-content;
    border-radius: 33px;
    text-transform: capitalize;
    display: inline-block !important;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

// modal error
.modalZindex {
  z-index: 5 !important;
}