$dropdown-hover: rgba($primary, 0.3);

.guidelines-constainer {
  .hero-title {
    text-transform: uppercase;
    padding: 0 15px;
    margin: 30px 0;
    border-left: solid 4px $body-color;
  }

  .small-hero-title {
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 0 30px;
  }
}

.guidelines-component-wrap {
  @include clearfix;
  margin-bottom: 50px;
}

// CHECK & RADIO BUTTON
$form-check-size: 18px !default;

.form-check {
  cursor: pointer;

  &.radio,
  &.check,
  &.selector {
    padding-left: 0;
    line-height: $form-check-size;

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -999;
    }

    .form-check-label {
      @include clearfix;
      padding-left: $form-check-size * 1.35;
      min-width: $form-check-size;
      min-height: $form-check-size;
      cursor: pointer;
      line-height: 1.5;

      span {
        pointer-events: none;

        &:after,
        &:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          top: 0;
        }

        &:before {
          width: $form-check-size;
          height: $form-check-size;
          border: solid 2px $border-color-form;
          border-radius: 0;
          left: 0;
        }

        &:after {
          width: $form-check-size * 0.556;
          height: $form-check-size * 0.556;
          background-color: $bg-secondary;
          border-radius: 100%;
          top: $form-check-size * 0.22;
          left: $form-check-size * 0.22;
          visibility: hidden;
        }
      }

      input:checked {
        ~span {
          &:before {
            border-color: $bg-secondary;
          }

          &:after {
            visibility: visible;
          }
        }
      }

      input:disabled {
        ~span {

          &:before,
          &:after {
            border-color: $disabled-color-200;
          }

          &:after {
            background-color: $disabled-color-200;
          }
        }
      }
    }

    &.form-check-inline {
      vertical-align: top;
      height: $form-check-size;
      // padding-left: $form-check-size;
      margin-right: 0;
      @include clearfix;

      .form-check-label {
        padding-left: 0;
      }
    }
  }

  &.check,
  &.selector {
    .form-check-label {
      span {
        &:before {
          border-radius: 100%;
          background-color: $white;
          border-color: $white;
        }

        &:after {
          border-radius: 0;
          background: none;
          border: solid $white;
          border-width: 0 0 2px 2px;

          top: 5px;
          left: 4px;

          width: 10px;
          height: 6px;
          transform: rotate(-45deg);
        }
      }

      input:checked {
        ~span {
          &:before {
            background-color: $bg-secondary;
          }

          &:after {
            visibility: visible;
          }
        }
      }

      input:disabled {
        ~span {
          &:before {
            border-color: $white;
          }

          &:after {
            visibility: visible;
            background-color: transparent;
            border-color: $disabled-color-200;
          }
        }
      }
    }

    &.form-check-inline {
      vertical-align: top;
      height: $form-check-size;
      // padding-left: $form-check-size;
      margin-right: 0;
      @include clearfix;
    }
  }

  &.selector {
    .form-check-label {
      span {
        &:before {
          border-radius: 0;
        }
      }
    }
  }

  &.radio {
    .form-check-label {
      span {
        &:before {
          border-radius: 100%;
        }
      }
    }
  }

  &.check-primary {
    .form-check-label {
      padding-left: 30px;
      color: $gray-999;

      span {
        &:before {
          border-radius: 2px;
          border-color: $gray-999;
        }
      }
    }
  }
}

// REACT SELECT
.react-select {
  .react-select__control {
    min-height: 38px;
    border-color: none !important;
    box-shadow: none !important;
    border-radius: $border-radius;
  }

  .react-select__placeholder {
    margin-left: 0;
    margin-right: 0;
    color: $gray-600;
  }

  .react-select__value-container {
    padding: 6px $input-btn-padding-x;

    // font-weight: $font-weight-medium;
    &.react-select__value-container--has-value {
      color: $gray-700;
    }
  }

  .react-select__indicator-separator {
    background-color: transparent;
  }

  .react-select__menu {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .react-select__menu-list {
    max-height: 240px;
  }

  .react-select__single-value {
    // color: $label-blue;
    font-size: 16px;
    line-height: 1.3;
  }

  .react-select__option {
    color: $body-color;

    &.react-select__option--is-focused {
      background-color: $dropdown-hover;
      color: gray("700");
    }

    &.react-select__option--is-selected {
      background-color: var(--main-color);
      color: $white;
    }
  }

  &.outline {
    .react-select__input {
      color: $label-blue;
    }

    .react-select__control {
      min-height: 38px;
      background-color: transparent;
      border-color: $gray-20;
    }

    .react-select__placeholder {
      color: #66666650;
      font-size: 12.25px;
    }

    .react-select__value-container {
      padding: 0 13px;

      .css-b8ldur-Input {
        margin: 2px 1px;
      }
    }

    .react-select__indicator {
      padding: 6px 10px;
      color: $gray-999;
    }

    .react-select__menu {
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .react-select__single-value {
      // color: $label-blue;
      font-size: 16px;
    }

    .react-select__option {
      color: $body-color;

      &.react-select__option--is-focused {
        background-color: $dropdown-hover;
        color: gray("700");
      }

      &.react-select__option--is-selected {
        background-color: var(--main-color);
        color: $white;
      }
    }
  }
}

.react-date-picker.form-control {
  padding: 0;
  color: $input-color;

  button {
    color: $input-color;
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: none;
  }

  .react-date-picker__calendar-button {
    padding: 0;
    width: 42px;
    height: 42px;
    font-size: 19px;
  }

  .react-date-picker__inputGroup {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: $input-padding-x * 0.65;

    &:lang(ar) {
      padding-left: 2px;
      padding-right: $input-padding-x * 0.65;
    }
  }

  * {
    outline: none !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: $gray-700;
  }

  .react-calendar__tile:disabled {
    color: graytext;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
  }

  .react-calendar__tile--active {
    color: $white !important;
    background: var(--main-color);
  }
}

.modal-middle {
  transform: translate(-50%, -50%) !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 376px;
  font-family: "Poppins", "Roboto", "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  @include media-breakpoint-down(sm) {
    width: 350px;
    margin: 0 !important;
  }

}

.custom-modal {
  border-radius: 5px;

  .modal-content {
    box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    padding: 29px 36px 0;
    text-align: center;
    border-bottom: none;
  }

  .modal-title {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    p {
      font-size: 12px;
      font-weight: $font-weight-normal;
      margin: 5px 0 0;
      text-transform: none;
    }
  }

  .modal-body {
    padding: 28px 36px;
  }

  .modal-footer {
    border-top: none;
    padding: 0 36px 43px;

    * {
      margin: 0;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 8px 9px;
    cursor: pointer;

    &.left {
      right: auto;
      left: 0;
    }
  }
}

[validationstate="error"] {
  .react-select__control {
    border-color: $red !important;
  }
}

[validationstate="success"] {
  .react-select__control {
    border-color: $green !important;
  }
}

.input-border-bottom {
  margin-bottom: 20px;

  .label {
    font-size: 14px;
    color: #9398a0;
    font-weight: $font-weight-book;
    padding: 0 5px;
  }

  .form-control {
    border-width: 0 0 1px 0;
    border-radius: 0;
    font-size: 18px;
    color: #27272d !important;
    height: 40px;
    padding: 0 5px;
  }

  .react-select {
    .react-select__control {
      border: 0;
      border-bottom: 1px solid var(--main-color);
      border-radius: 0;
    }
  }
}

.dropdown-menu {
  width: 168px;
  border: none;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 5px 10px 0 rgba(black, 0.15);
  // padding: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.custom-modal2 {
  border-radius: 5px;

  .modal-content {
    box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    text-align: center;
    border-bottom: none;
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .modal-title {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    p {
      font-size: 12px;
      font-weight: $font-weight-normal;
      margin: 5px 0 0;
      text-transform: none;
    }
  }

  .modal-body {
    padding: 0px !important;
  }

  .modal-footer {
    border-top: none;

    * {
      margin: 0;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 8px 9px;
    cursor: pointer;

    &.left {
      right: auto;
      left: 0;
    }
  }
}