$boxes: 10;
$durationAll: .8s;
$duration: - $durationAll / $boxes;
$color: $flexbox-loader-color;

.flexbox-loader-wrapper {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // top: 0;
    // width: 100%;
    height: 2px;
    margin: auto;
    opacity: 0;
    @include transition(transform .15s ease-in-out, $custom-all-transition);
    &.show {
        opacity: 1;
    }
}
.flexbox-loader {
    height: 100%;
    display: flex;
    transform: translateZ(0);
    
    div {
        flex: 1;
        background: #{$color};
        animation: go #{$durationAll} infinite alternate ease;
        box-shadow: 0 0 20px #{$color};
        
        @for $i from 1 through $boxes {
            &:nth-child(#{$i}) {
                animation-delay: $duration * ($boxes - $i);
            }
        }
    }

}


.global-loader {
    position: fixed;
    background-color: rgba($gray-27, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    .page-loading {
        pointer-events: none;
        user-select: none;
    }
}
  
    
@keyframes go {
    100% {
        background: transparent;
        flex: 10;
        box-shadow: 0 0 0 transparent;
    }
}


/* Flash class and keyframe animation */
.flashit {
    -webkit-animation: flash linear .5s 20;
    animation: flash linear .5s 20; 
  }
  @-webkit-keyframes flash {
      0% { box-shadow: inset 0 0 20px 2px rgba(black, .5); } 
      25% { box-shadow: inset 0 0 5px 2px black; } 
      50% { box-shadow: inset 0 0 20px 2px rgba(black, .5); } 
      75% { box-shadow: inset 0 0 5px 2px black; }
      100% { box-shadow: inset 0 0 20px 2px rgba(black, .5); } 
  }
  @keyframes flash {
    0% { box-shadow: inset 0 0 20px 2px rgba(black, .5); } 
    25% { box-shadow: inset 0 0 5px 2px black; } 
    50% { box-shadow: inset 0 0 20px 2px rgba(black, .5); } 
    75% { box-shadow: inset 0 0 5px 2px black; }
    100% { box-shadow: inset 0 0 20px 2px rgba(black, .5); } 
  }

.blink {
    background-color: $yellow !important;
    color: $gray-27 !important;
    // -webkit-animation: blink linear .5s infinite;
    // animation: blink linear .5s infinite;
}
// @-webkit-keyframes blink {
//     0%, 49% {
//         opacity: .5;
//     }
//     50%, 100% {
//         background-color: $yellow;
//         color: $gray-27;
//         opacity: 1;
//     }
// }

// @keyframes blink {
//     0%, 49% {
//         opacity: .5;
//     }
//     50%, 100% {
//         background-color: $yellow;
//         color: $gray-27;
//         opacity: 1;
//     }
// }
@-webkit-keyframes blink {
    50% {
        background-color: $yellow;
        color: $gray-27;
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        background-color: $yellow;
        color: $gray-27;
        opacity: 1;
    }
}