@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot?den7ld");
  src: url("../fonts/icomoon/fonts/icomoon.eot?den7ld#iefix") format("embedded-opentype"),
    url("../fonts/icomoon/fonts/icomoon.ttf?den7ld") format("truetype"),
    url("../fonts/icomoon/fonts/icomoon.woff?den7ld") format("woff"),
    url("../fonts/icomoon/fonts/icomoon.svg?den7ld#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icomoon,
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\e919";
}

.icon-horse-racing:before {
  content: "\e913";
}

.icon-4:before {
  content: "\e913";
}

.icon-football:before {
  content: "\e900";
}

.icon-1:before {
  content: "\e900";
}

.icon-tennis:before {
  content: "\e901";
}

.icon-3:before {
  content: "\e901";
}

.icon-cricket:before {
  content: "\e902";
}

.icon-2:before {
  content: "\e902";
}

.icon-envelope:before {
  content: "\e903";
}

.icon-phone:before {
  content: "\e904";
}

.icon-facebook:before {
  content: "\e905";
}

.icon-email:before {
  content: "\e906";
}

.icon-whatsapp:before {
  content: "\e907";
}

.icon-youtube:before {
  content: "\e908";
}

.icon-logout:before {
  content: "\e909";
}

.icon-clock:before {
  content: "\e90a";
}

.icon-close-circle:before {
  content: "\e90b";
}

.icon-previous:before {
  content: "\e90c";
}

.icon-next:before {
  content: "\e90d";
}

.icon-up:before {
  content: "\e90e";
}

.icon-down:before {
  content: "\e90f";
}

.icon-menu:before {
  content: "\e910";
}

.icon-cancel:before {
  content: "\e911";
}

.icon-calendar:before {
  content: "\e912";
}

.icon-trash:before {
  content: "\e914";
}

.icon-mute:before {
  content: "\e915";
}

.icon-unmute:before {
  content: "\e916";
}

.icon-plus-border:before {
  content: "\e917";
}

.icon-minus-border:before {
  content: "\e918";
}

.icon-language:before {
  content: "\e91a";
}

.icon-cog:before {
  content: "\e994";
}

.icon-cogs:before {
  content: "\e995";
}

.icon-dollar:before {
  content: "\e91b";
}

.icon-presentation:before {
  content: "\e91c";
}

.icon-trophy:before {
  content: "\e91d";
}

.icon-lock:before {
  content: "\e91e";
}

.icon-info:before {
  content: "\e91f";
}

.icon-meter:before {
  content: "\e920";
}

.icon-earth:before {
  content: "\e921";
}

.icon-arrow-right:before {
  content: "\e922";
}

.icon-tv:before {
  content: "\e927";
}