.notification-container{
    background-color: $white;
    border:1px solid $border-color;
    border-radius: 6px;
    margin-bottom: 14px;
    padding-bottom: 20px;
}
.notification-row{
    border-bottom: 1px solid $border-color;
    padding: 16px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .notification-item{
        color: $black;
        line-height: 19px;
        p{
            margin: 0;
        }
        &.p-r{
            padding-right: 40px;
        }
    }
}