.exposure_details {
  .modal-content {
    i.close-btn {
      float: right;
      color: white;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .modal-header {
      background-color: var(--main-color);
      color: white;
    }
    .modal-body {
      table.table {
        thead {
          tr {
            background-color: var(--main-color);
            color: white;
            th {
              text-align: right;
            }
            th:first-child {
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            &.dena {
              background-color: $back-bg;
            }
            &.lena {
              background-color: $lay-bg;
            }
            td {
              text-align: right;
            }
            td:first-child {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.new_score_board {
  color: white;
  background-color: #6e8fc9 !important;
  text-align: center !important;
  font-weight: 400 !important;
  margin: 0px;
  .col-12 {
    padding: 5px;
    border-bottom: 1px solid white;
    p {
      color: white;
      padding-left: 20px;
      font-weight: 400 !important;
    }
  }
}
