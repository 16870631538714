
.language-dialog {
    border-radius: 10px;
    user-select: none;
    max-width: 640px;
    @include media-breakpoint-down(sm) {
        max-width: 320px;
        margin: auto;
    }
    .title {
        cursor: default;
        font-size: 20px;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 60px;
        margin: 0;
        background: url(../img/dots.svg) no-repeat -30px 30px;

        @include media-breakpoint-down(sm) {
            padding-top: 20px;
            padding-bottom: 30px;
            background: url(../img/dots.svg) no-repeat -60px 12px;
        }
    }
    .lang-btn-row {
        @include flexwrap;
        padding: 0 25px;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    .lang-btn {
        cursor: pointer;
        height: 120px;
        border-radius: 5px;
        padding: 12px 0;
        text-align: center;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
        color: #000;
        @include flexwrap;
        flex-direction: column;
        justify-content: center;
        margin: 0 15px;
        @include media-breakpoint-down(sm) {
            margin: 15px 0 0;
            display: block;
            &:first-child {
                margin-top: 0;
            }
        }
        .text {
            flex-grow: 0;
            font-size: 22px;
            line-height: 1.2;
            margin: 0 0 3px;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
            }
            & + .text {
                font-size: 18px;
                margin: 0;
                color: rgba(0,0,0,0.5);
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
        }
        &.active {
            background: var(--main-color);
            box-shadow: 0 8px 13px 0 rgba(0,0,0,0.21);
            color: #fff;
            .text {
                & + .text {
                    color: rgba(#fff,0.5);
                }
            }
        }
    }
    .lang-modal-footer {
        padding: 70px 0 40px;
        background: url(../img/dots.svg) no-repeat right 70%;

        @include media-breakpoint-down(sm) {
            padding: 30px 0 20px;
            background: url(../img/dots.svg) no-repeat right 25px;
        }

    }
    .confirm-btn {
        display: block;
        margin: 0 auto;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background-color: #fff;
        padding: 0;
        background: red;
        background: url(../img/tick.svg) no-repeat center;
        border: none;
        &:disabled {
            filter: grayscale(100%);
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}
a.lang-btn {
    font-size: 19px;
    margin-right: 2.5rem;
    text-align: center;
    float: left;
    min-width: 27vh !important;
    padding: 3px 25px;
    box-shadow: 0 0 15px 5px #e4e4e4;
    background-color: #fff;
    color: red;
    &.active {
        background-color: #23527c;
        color: #fff;
    }
}
.casino_game_images{
    .modal-content{
        background-color: transparent;
        border: 0px solid transparent;
    }
}