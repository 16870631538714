.pagination {
    display: inline-block;
    white-space: nowrap;
    @include clearfix;
    > li {
        float: left;
        min-width: 32px;
        height: 32px;
        background-color: $white;
        margin: 0 6px;
        border-radius: 6px;
        text-align: center;
        color: var(--main-color);
        font-weight: $font-weight-medium;
        cursor: pointer;
        > a {
            display: block;
            padding: 7px 5px;
            border-radius: 6px;
        }
        &.active {
            cursor: default;
            color: $white;
            background-color: var(--main-color);
            > a {
                color: $white;
            }
        }
        &.disabled {
            background-color: $gray-100;
            color: $disabled-color-100;
        }
    }
}

:lang(ar) {
    .pagination {
        > li {
            float: right;
        }
    }
}