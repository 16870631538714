.page-wrap{
    margin: 20px 0;
}
.inline-form{
    &.sm{
        .btn{
            padding: 4px 14px;
            + .btn{
                margin-left: 8px;
            }
    
        }
    }
    .form-group{
        label{
            color: rgba(39,39,45,0.5);
        }
        .form-control{
            height: 30px;
            border-color: $border-color;
            .react-date-picker__inputGroup{
                padding-top: 4px;
                padding-bottom: 4px;
            }
            .react-date-picker__calendar-button{
                width: 32px;
                height: 32px;
            }
        }
    }
    .btn{
        border-radius: 24px;
        margin-top: 28px;
        + .btn{
            margin-left: 15px;
        }

    }
    .react-select {
        .react-select__control {
            height: 30px;
            min-height: 30px;
            border-radius: 4px;
            border-color: $border-color;
        }
        .react-select__single-value{
            font-size: 14px;
        }
        .react-select__value-container{
            padding: 0 1rem;
        }
        
        .react-select__dropdown-indicator{
            padding:4px
        }
    }
    @media (max-width:767px) {
        .btn{
            margin-top: 15px;
            &:nth-child(4){
                margin-left: 0;
            }
        }
    }
}
.expand-bets{
    background-color: var(--main-color);
    color: $white !important;
    font-weight: $font-weight-bold;
    padding: 0 5px;
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    text-align: center;
}

.static-container{
    background-color: $white;
    border:1px solid $border-color;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 14px;
}
.unorderd-list{
    padding: 0 20px;
    li{
        list-style-type: disc;
    }
}
.s-head{
    font-size: 16px;
    font-weight: $font-weight-medium;
    margin-bottom: 15px;
}
.mb20{
    margin-bottom: 20px;
}
.mb30{
    margin-bottom: 30px;
}
.m-head{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    color: $gray-27;
    padding: 28px 20px;
    margin: 0;
}
.list-circle{
    padding: 0 15px;
    li{
        list-style-type: circle;
    }
}
.mv20{
    margin:20px 0 
}
.static-body{
    padding: 0 20px 20px 20px;
}

@media (max-width:767px){
    .pl-responise-tbl{
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
        
        thead  { 
            tr{
                position: absolute;
                top: -9999px;
                left: -9999px;

            }
        }
        tr{
            border-bottom: 15px solid $body-bg;
            &.active-row{
                td:nth-of-type(1) { display: none;}
                td:nth-of-type(3) { display: none;}
            }
        }
        td { 
            /* Behave  like a "row" */
            min-height: 38px;
            border: none;
            border-bottom: 1px solid #eee; 
            position: relative;
            padding: 7px 0 7px 50%;
            &:before { 
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                width: 45%; 
                padding-right: 10px; 
                white-space: nowrap;
            }
            text-align: left !important;
        }
        	/*
	Label the data
	*/td:nth-of-type(1){
       // padding: 10px 10px 0 10px;
      //  border-bottom: 0;
      //  min-height: auto;
    }
    td:nth-of-type(2){
    //    padding:0 10px 10px 10px;
    }
	td:nth-of-type(1):before { content: "Date";}
	td:nth-of-type(2):before { content: "Game Name"; }
	td:nth-of-type(3):before { content: "Sport"; }
	td:nth-of-type(4):before { content: "Stake"; }
	td:nth-of-type(5):before { content: "Gross P/L"; }
	td:nth-of-type(6):before { content: "Commission"; }
	td:nth-of-type(7):before { content: "Net Profit"; }
	 }
}