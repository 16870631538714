.banner-home {
  height: 100%;
  padding-bottom: 100px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10%;
    background-image: url("../img/Shape.png");
    height: 508px;
    width: 20%;
    background-repeat: no-repeat;
    display: block;
  }

  .right-section {
    float: right;
    width: 60%;
    text-align: right;
  }

  .left-section {
    width: 40%;
    margin-top: 87px;

    h1 {
      color: $gray-27;
      font-size: 86px;
      font-weight: $font-weight-black;
      letter-spacing: 0;
      line-height: 90px;
      margin: 45px 0 10px 0;
    }

    p {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 23px;
      margin: 0;
    }

    .btn {
      min-width: 150px;
      margin-top: 60px;
      z-index: 1;
      position: relative;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 0;

    &:after {
      display: none;
    }

    .right-section {
      float: none;
      width: 100%;
    }

    .left-section {
      width: 100%;
      margin-top: 50px;

      h1 {
        font-size: 36px;
        line-height: 48px;
      }

      .btn {
        min-width: 100px;
        padding: 2px;
        margin-top: 30px;
      }
    }
  }
}

.home-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px;
}

.sm-banner {
  position: relative;
  overflow: hidden;

  figure {
    border-radius: 12px;
    overflow: hidden;
  }

  .sm-banner-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;

    i {
      color: $white;
      float: left;
      font-size: 88px;
      margin-right: 22px;
    }

    h4 {
      font-size: 36px;
      line-height: 48px;
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 23px;
    }

    .sm-banner-txt-desc {
      overflow: hidden;
      padding: 0 22px;
    }

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;

      i {
        font-size: 24px;
        margin: 0;
        float: none;

        &.view-xs {
          display: inline-block;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 29px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    overflow: inherit;

    .max-wids {
      min-height: 80px;
      max-width: 100%;
    }
  }
}

.sports-card {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  color: $gray-27;
  padding: 19px 20px;

  i {
    font-size: 48px;
  }

  h4 {
    font-size: 24px;
    line-height: 33px;
    margin: 15px 0 4px 0;
    font-weight: $font-weight-bold;
  }

  p {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    padding: 10px;

    i {
      font-size: 24px;
      display: inline-block;
      margin-right: 15px;
    }

    h4 {
      font-size: 18px;
      line-height: 23px;
      display: inline-block;
      vertical-align: 5px;
      margin: 0;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      margin: 7px 0 0 0;
    }
  }
}

.league-list {
  .league-list-item {
    background-color: $white;
    box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 50px;
    float: left;
    width: calc(100% / 5 - 40px);
    position: relative;
    height: 160px;
    margin-bottom: 20px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 78%;
    }

    &:nth-child(5) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      width: calc(100% / 3 - 7px);
      margin-right: 7px;
      height: 100px;

      &:nth-child(5) {
        margin-right: 7px;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      &:last-child {
        display: none;
      }

      img {
        max-width: 70%;
      }
    }
  }
}

.mt20 {
  margin-top: 20px;
}

.link-txt {
  text-align: center;
  display: block;
  margin: 70px 0;
  font-size: 16px;
  font-weight: $font-weight-medium;
}

.heading-inner {
  font-size: 40px;
  color: $black;
  line-height: 53px;
}

.head-with-shadow {
  font-size: 40px;
  line-height: 53px;
  color: $black;
  position: relative;
  z-index: 1;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;

  span {
    color: rgba(0, 0, 0, 0.05);
    font-size: 60px;
    line-height: 90px;
    position: absolute;
    font-weight: $font-weight-medium;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  @media (max-width: 767px) {
    z-index: 0;
    font-size: 20px;
    line-height: 27px;

    span {
      font-size: 30px;
      line-height: 40px;
      top: 22px;
    }
  }
}

.shape-wrap {
  position: relative;
  padding-bottom: 125px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url("../img/dots.png");
    height: 45px;
    width: 20%;
    background-repeat: no-repeat;
    display: block;
  }
}

.shape-wrap-right {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: -40%;
    background-image: url("../img/Shape.png");
    height: 508px;
    width: 20%;
    background-repeat: no-repeat;
    display: block;
    transform: rotate(180deg);
  }
}

.max-wid {
  max-width: 100%;
}

.view-xs {
  display: none;
}

.mob-header {
  display: none;
}

@media (max-width: 767px) {
  .mob-header {
    display: block;
    text-align: center;
    background-color: var(--main-color);
    padding: 15px;
  }

  .link-txt {
    margin: 30px 0;
  }

  .hidden-xs {
    display: none;
  }

  .view-xs {
    display: block;
  }

  .shape-wrap {
    padding-bottom: 0px;

    &:after {
      display: none;
    }
  }

  .shape-wrap-right {
    &:after {
      display: none;
    }
  }
}

// dashboard
.home-page {
  .card {
    background-color: var(--main-color);
    color: #fff;
    line-height: 2;
    height: 70px;
    margin: 1rem auto;
    position: relative;
    width: 230px;
    text-align: center;
    display: block;
    padding: 18px;
    font-size: 18px;
    letter-spacing: 2px;
    border-radius: 50px;

    &:hover {
      box-shadow: "0 4px 4px rgb(0 0 0 / 25%), 0 4px 4px rgb(0 0 0 / 22%)";
      background-color: #fff;
      color: var(--main-color);
    }

    span {
      float: left;
      padding-left: 5px;
      font-size: 35px;
    }
  }
}

//Casino Supernowa css
iframe.casinosupernowa {
  width: 100%;
  height: calc(100vh - 75px);
  border: 0px solid transparent;
}

.scoreboard-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 10000;
  overflow: hidden;
  visibility: hidden;
}