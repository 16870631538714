.app-footer {
    background-color: $footer-bg;
    // height: 335px;
    padding: 23px 13px 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    .logo {
        width: 86px;
        height: 24px;

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .web-description {
        margin-top: 15px;
        font-size: 14px;
        line-height: 21px;
        color: rgba($white, .5);

        @include media-breakpoint-down(md) {
            margin-bottom: 50px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }
}

.footer-heading {
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: rgba($white, .5);
    height: 24px;

    @include media-breakpoint-down(sm) {
        height: auto;
        margin-top: 50px;
    }
}

.logo-list {
    >* {
        margin-top: 20px;

        >img {
            max-width: 100%;
        }
    }
}

.support-list {
    margin: 20px 0 0;

    >li {
        font-size: 16px;
        line-height: 1.3;
        color: rgba($white, .5);
        margin-top: 17px;

        &:first-child {
            margin-top: 0;
        }

        i {
            font-size: 18px;
            float: left;
            margin-right: 15px;
        }

        .phone {
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.social-list {
    @include clearfix;
    border-top: solid 1px rgba($black, .1);
    margin: 10px 0 0;
    padding-top: 10px;

    a {
        float: left;
        font-size: 20px;
        color: rgba($white, .5);
        margin-left: 25px;

        @include media-breakpoint-down(sm) {
            margin-left: 15px;
        }

        &:hover {
            color: $yellow;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.footer-copy-right {
    @include container-margin-none;
    margin-left: -13px;
    margin-right: -13px;
    color: rgba($white, .5);
    font-size: 14px;
    line-height: 1.2;
    border-top: solid 1px rgba($black, .1);
    margin-top: 15px;
    padding: 10px 0;
    text-align: center;
}


.whatsapp-badge {
    position: fixed;
    bottom: 2%;
    right: 2%;
    animation: whatsapp_anim 1.5s linear infinite;
    // width: 70px !important;
    z-index: 100;
}

@keyframes whatsapp_anim {
    0% {
        transform: scale(1)
    }

    25% {
        transform: scale(0.9)
    }

    50% {
        transform: scale(1.1)
    }

    75% {
        transform: scale(0.9)
    }

    100% {
        transform: scale(1)
    }
}