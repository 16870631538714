.btn-in-rules {
  @include media-breakpoint-down(sm) {
    display: grid;
  }
  @include media-breakpoint-down(md) {
    display: grid;
  }
  .btn_inplay {
    color: white;
    background-color: var(--main-color) !important;
    padding: 15px 35px;
    border-radius: 33px;
    font-size: 15px !important;
    margin-left: 2px;
    @include media-breakpoint-down(sm) {
      margin-top: 2px;
      margin-left: 0px;
    }
  }
}

.hindiEnglishButton {
  float: right;
  .btn {
    color: white;
    background-color: rgb(189, 189, 189);
    &.active-button {
      background-color: var(--main-color) !important;
    }
  }
  @include media-breakpoint-down(sm) {
    float: none;
    margin: 0 calc(50% - 90px);
  }
}
.hindi-note-container {
  .hindi-note-card-container {
    width: 75%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .card-header-container {
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.16), 0 1px 10px rgba(0, 0, 0, 0.23) !important;
      padding: 15px !important;
      font-weight: 600;
      letter-spacing: 1px;
      color: #945100;
      margin: 20px 1% 0 !important;
      .list-group {
        margin-top: 0px;
        width: auto;
        @include media-breakpoint-down(sm) {
          margin: 0px;
        }
        .list-group-item {
          border: 0px solid transparent;
          &:hover {
            background-color: transparent;
            color: red;
          }
          @include media-breakpoint-down(sm) {
            margin: 0px;
          }
        }
      }
    }
    ol.hindi-rules-list {
      width: 100% !important;
      margin: 0 !important;
      display: table;
      .match-name {
        color: #a95f00 !important;
      }
      li {
        display: table-caption;
        border-bottom: 2px solid #c4c4c4;
        padding: 10px 20px;
        color: var(--main-color);
      }
      li::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
    }
  }
}
.english-note-container {
  .english-note-card-container {
    width: 75%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .card-header-container {
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.16), 0 1px 10px rgba(0, 0, 0, 0.23) !important;
      padding: 15px !important;
      font-weight: 600;
      letter-spacing: 1px;
      color: #945100;
      margin: 20px 1% 0 !important;
      .list-group {
        margin-top: 0px;
        width: auto;
        @include media-breakpoint-down(sm) {
          margin: 0px;
        }
        .list-group-item {
          border: 0px solid transparent;
          &:hover {
            background-color: transparent;
            color: red;
          }
          @include media-breakpoint-down(sm) {
            margin: 0px;
          }
        }
      }
    }
    ol.english-rules-list {
      width: 100% !important;
      margin: 0 !important;
      display: table;
      .match-name {
        color: #a95f00 !important;
      }
      li {
        display: table-caption;
        border-bottom: 2px solid #c4c4c4;
        padding: 10px 20px;
        color: var(--main-color);
      }
      li::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
    }
  }
}
