.left-navigation {
  @include clearfix;
  background-color: $white;
  margin-left: -10px;
  height: 100%;
  .back-nav {
    background-color: $gray-40;
    position: relative;
    padding-left: 26px;

    i {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 34px;
      padding: 11px 10px;
    }
  }
}

.left-nav-item,
.back-nav {
  padding: 10px 15px;
  font-size: 12px;
  line-height: 1.2;
  color: $gray-27;
  border-top: solid 1px rgba($gray-999, 0.5);
  text-transform: uppercase;
  cursor: pointer;
  @include text-truncate;
  &:first-child {
    border-top: none;
  }
  &.posting {
    height: 36px;
    background: url(../img/spiner-light.svg) center no-repeat;
    background-size: 25px;
  }
  &.active {
    background-color: rgba(22, 128, 94, 0.2);
  }
}
