.chng-pass {
  position: absolute;
  width: 90%;
}
.change-paswd-wrap {
  background-color: #fff;
  padding: 0px 0px 23px;
  border-radius: 3px 3px 0 0;
  margin-top: 7%;
  margin-left: 32%;
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0px 0px 25px;
    margin-top: 25%;
    margin-left: 0%;
  }
  .form-control {
    margin: 20px 25px 0 !important;
    width: 380px;
    display: block !important;
    border: none !important;

    padding: 0 !important;
    border-bottom: solid 1px #267531 !important;
    border: solid 1px var(--main-color) !important;
    color: var(--main-color) !important;
    border-radius: 0 !important;
    outline: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2) !important;
    letter-spacing: 0.4px !important;
    font-size: 14px !important;
    text-indent: 15px;
    letter-spacing: 1.4px !important;
    font-family: "open-sans-semibold" !important;
    @include media-breakpoint-down(sm) {
      width: 85%;
    }
  }

  .btn {
    // width: 383px;
    background-color: var(--main-color) !important;
    color: #fff;
    border: none !important;
    cursor: pointer !important;
    border-radius: 3px !important;
    padding: 10px !important;
    width: 377px;
    letter-spacing: 2px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2) !important;
    @include media-breakpoint-down(sm) {
      width: 85%;
    }
  }
}
.login-head {
  height: 100px;
  width: 100%;
  font-size: 24px;
  text-align: center;
  background: var(--main-color);
  color: white;
  line-height: 100px;
  letter-spacing: 2px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 5%);
}
