$fs5: 5px;
$fs6: 6px;
$fs7: 7px;
$fs8: 8px;
$fs9: 9px;
$fs10: 10px;
$fs11: 11px;
$fs12: 12px;
$fs13: 13px;
$fs14: 14px;
$fs15: 15px;
$fs16: 16px;
$fs17: 17px;
$fs18: 18px;
$fs20: 20px;
$fs25: 25px;
$fs30: 30px;

.font5 {
    font-size: $fs5 !important;
}

.font6 {
    font-size: $fs6 !important;
}

.font7 {
    font-size: $fs7 !important;
}

.font8 {
    font-size: $fs8 !important;
}

.font9 {
    font-size: $fs9 !important;
}

.font10 {
    font-size: $fs10 !important;
}

.font11 {
    font-size: $fs11 !important;
}

.font12 {
    font-size: $fs12 !important;
}

.font13 {
    font-size: $fs13 !important;
}

.font14 {
    font-size: $fs14 !important;
}

.font15 {
    font-size: $fs15 !important;
}

.font16 {
    font-size: $fs16 !important;
}

.font17 {
    font-size: $fs17 !important;
}

.font18 {
    font-size: $fs18 !important;
}

.font20 {
    font-size: $fs20 !important;
}

.font25 {
    font-size: $fs25 !important;
}

.font30 {
    font-size: $fs30 !important;
}