.login-container-row {
  // background: url(../../Assets/img/home_bg.png) center center no-repeat;
  background: linear-gradient(45deg, var(--main-color), var(--secondary-color));
  background-size: cover !important;
  height: 100vh;
  width: 100%;
  padding-top: 100px;

  @include media-breakpoint-down(sm) {
    padding-top: 15px;
  }

  .sports {
    text-align: center;

    img {
      width: 250px;

      @include media-breakpoint-down(sm) {
        width: 150px;
      }

      &.sport_img {
        margin-top: -20px;

        @include media-breakpoint-down(sm) {
          margin-top: -8px;
        }
      }

      &.casino_img {
        @include media-breakpoint-down(sm) {
          margin-top: 7px;
        }
      }
    }

    .sport_name {
      position: absolute;
      right: 125px;
      top: 65px;
      color: white;
      font-size: 14px;
      font-weight: 900;

      &.ft {
        top: 55px;

        @include media-breakpoint-down(sm) {
          right: 30px;
          top: 35px;
        }
      }

      &.casino {
        top: 50px;

        @include media-breakpoint-down(sm) {
          right: 30px;
          top: 35px;
        }
      }

      @include media-breakpoint-down(sm) {
        right: 30px;
        top: 35px;
      }
    }
  }

  .mobile-view {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .login-container {
    .site_name {
      text-align: center;
      color: white;
      margin-top: 3rem;
      margin-bottom: 2rem;
      font-weight: 900;

      @include media-breakpoint-down(sm) {
        margin-top: 0px;
      }
    }

    .login_label {
      color: white;
    }

    .batting_platform {
      color: #9d87ad;
    }

    form {
      .simple_captcha {
        position: absolute;
        right: 19px;
        top: 3px;
        background-color: #1d1141;
        padding: 2px 20px;
        color: white;
        border-radius: 5px;
        font-size: 18px;
      }

      button.login_btn {
        background-color: #eb8300;
        color: white;
        font-weight: bold;
      }

      a.download_apk {
        background-color: #6753bf;
        color: white;
        font-weight: bold;
        width: 100%;
        border: 3px solid #eb8300;

        span {
          background-color: #eb8300;
          position: absolute;
          left: 20px;
          padding: 5px 78px;
          top: 5px;

          @include media-breakpoint-down(sm) {
            padding: 5px 95px;
          }
        }

        img {
          float: right;
        }
      }
    }
  }
}

.error {
  color: red;
  text-align: center;
  margin-top: -24px;
}

.workinprogress {
  background: url(../../Assets/img/73.png) center center no-repeat;
  background-size: cover !important;
  height: 100%;
  background-position: center;
  position: relative;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;

  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  hr {
    margin: auto;
    width: 40%;
  }
}


.wall_container {
  // height: calc(100vh - 42px);
  height: 100vh;
  background: linear-gradient(45deg, var(--main-color), var(--secondary-color));

  .company_name {
    color: white;
    font-weight: 900;
    text-align: center;
    margin-top: 3rem !important;
    margin-bottom: 2rem !important;
  }

  .login_label {
    color: white;
    text-align: center;
  }

  .batting_platform {
    text-align: center;
    color: #9d87ad;
  }

  form {
    .card {
      border-radius: 10px;

      .card-body {
        padding: 35px 20px 20px 20px;

        .form-group {
          input.form-control {
            border: 0px transparent;
            background-color: #ececec !important;
            padding: 25px 20px;
            font-size: 18px;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #898989;
              opacity: 1;
              /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #898989;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #898989;
            }
          }
        }

        .error {
          color: red;
          text-align: center;
        }

        .simple_captcha {
          position: absolute;
          right: 25px;
          top: 8px;
          background-color: #838383;
          padding: 2px 20px;
          color: white;
          border-radius: 5px;
          font-size: 20px;
        }

        .login_btn {
          &.btn {
            // background-image: linear-gradient(to right, #f34078, #fc7c55);
            font-weight: bold;
            padding: 12px;
          }
        }
      }
    }
  }
}